import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Grid, Typography, Radio, TextField } from "@mui/material";
import { Box } from "@mui/system";
import bg from "../../assets/images/header.png";
import demo from "../../assets/images/demo.jpg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { addNewData } from "../../redux/slices/dataSlice";
import { addNewKeywords } from "../../redux/slices/keywordsSlice";
import { addImagetexts } from "../../redux/slices/imagetextSlice";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Alert from "@mui/material/Alert";
import { useStyles } from "./styles";
import { getBusinessCard } from "../../redux/slices/businessCardSlice";
/* eslint-disable no-useless-escape*/

const StepTwo = ({
  handleNext,
  handleBack,
  fullPackage,
  isVideo,
  setIsVideo,
  language,
  isLoggedIn,
  fileNames,
}) => {
  const classes = useStyles();
  const [save, setSave] = useState(false);
  const [saveButton, setSaveButton] = useState(true);
  const [voiceId, setVoiceId] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [stepImage, setStepImage] = useState(null);
  const [businessCard, setBusinessCard] = useState("");
  
  const { i18n, t, ready } = useTranslation();

  const dispatch = useDispatch();

  const localData = JSON.parse(localStorage.getItem("content"));
  const projectImage = localStorage.getItem("image");

  const stepKeywords = useSelector(
    (state) => state?.keywordsReducer?.keywords?.[0]?.result
  );

  useEffect(() => {
    JSON.parse(window.localStorage.getItem("project_id"));
  }, []);

  useEffect(() => {
    try {
     setTimeout(() => {
      setBusinessCard(JSON.parse(window.localStorage.getItem("do_business_card")));
    }, 1000)
    } catch (error) {
      console.log(error);
    }
  }, []);
  
  const onGetImageTexts = () => {
    const body = {
      action: "get_imagetexts",
      project_id: JSON.parse(window.localStorage.getItem("project_id")),
    };
    dispatch(addImagetexts(body));
  };

  const onSaveVideo = (values) => {
    const body = {
      action: "update_project",
      id: JSON.parse(window.localStorage.getItem("project_id")),
      data: {
        pitch: values.pitch,
        amember_user_id: 12346,
        email: values.email,
        gender: values.gender,
        voice_id: voiceId,
        generate_with_own_bc: fileNames.length !== 0 ? 0 : 1,
        lang_report: !window.localStorage.getItem("language")
          ? language
          : window.localStorage.getItem("language"),
      },
    };
    dispatch(addNewData(body));
  };

  const onGetVideo = (values) => {
    const body = {
      action: "get_project",
      id: JSON.parse(window.localStorage.getItem("project_id")),
      data: {
        pitch: values.pitch,
        amember_user_id: 12346,
        email: values.email,
        gender: values.gender,
        voice_id: voiceId,
        generate_with_own_bc: fileNames.length !== 0 ? false : true,
        lang_report: !window.localStorage.getItem("language")
          ? language
          : window.localStorage.getItem("language"),
      },
    };

    dispatch(addNewData(body));
  };

  const onGetKeywords = () => {
    const body = {
      action: "get_keywords",
      id: JSON.parse(window.localStorage.getItem("project_id")),
    };

    dispatch(addNewKeywords(body));
  };

  const handleChangeGender = (event) => {
    setSelectedValue(event.target.value);
  };

  const fullPackageFunction = () => {
    localStorage.setItem("fullPackage", Boolean(fullPackage));
  };

  const formValidation = Yup.object({
    email: Yup.string("Enter your email")
      .email(() => i18n.t("email must be a valid email"))
      .required(() => i18n.t("Required"))
      .nullable(),
  });

  const onGetBusinessCard = () => {
    const body = {
      action: "do_business_card",
      project_id: JSON.parse(window.localStorage.getItem("project_id")),
    };
    dispatch(getBusinessCard(body));
  };
  
  const emailRegExp =
    "^[a-zA-Z0-9_'+*/^&=?~{}-](.?[a-zA-Z0-9_'+*/^&=?~{}-])*@((d{1,3}.d{1,3}.d{1,3}.d{1,3}(:d{1,3})?)|(((([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9])|([a-zA-Z0-9]{1,2}))[.]{1})+([a-zA-Z]{2,6})))$";

  useEffect(() => {
    setSelectedValue(window.localStorage.getItem("gender"));
  }, []);

  useEffect(() => {
    setStepImage(window.localStorage.getItem("image"));
  }, []);

  useEffect(() => {
    setIsVideo(window.localStorage.getItem("is_video"));
  }, [setIsVideo]);

  useEffect(() => {
    window.localStorage.getItem("gender");
    window.localStorage.getItem("email");
  }, []);

  if (ready) {
    return (
      <Box>
        <img
          src={bg}
          alt="header"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
        {!isLoggedIn && (
          <Alert severity="warning"> {t("You are in the demo mode")}</Alert>
        )}
        <Formik
          initialValues={{
            amember_user_id: 12346,
            gender: window.localStorage.getItem("gender") || "",
            email: window.localStorage.getItem("email") || "",
            voice_id: "Matthew",
          }}
          onSubmit={(values) => {
            onSaveVideo(values);
            onGetVideo(values);
            setTimeout(() => {
              onGetKeywords(values);
            }, 1000);
            setTimeout(() => {
              onGetImageTexts(values);
            }, 1500);
          }}
          validationSchema={formValidation}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            touched,
            errors,
            handleChange,
          }) => (
            <Form>
              <motion.div
                initial={{ width: 0 }}
                animate={{
                  width: "100%",
                  transition: {
                    property: "max-height",
                    duration: 0.2,
                    transitionTimingFunction: "easeIn",
                  },
                }}
              >
                <Box p={4}>
                  <hr />
                  <Typography className={classes.step} variant="h5">
                    {t("Step Two")}
                  </Typography>
                  <hr />

                  {localData ? (
                    <Box
                      className={classes.imageBlock}
                      display={"flex"}
                      flexWrap={"wrap"}
                      style={{
                        justifyContent:
                          localData.length === 1 ? "flex-end" : "space-evenly",
                      }}
                    >
                      {localData.map((item, index) => {
                        return (
                          <Box key={index} className={classes.stepImageBox}>
                            {isVideo && item.is_video === 0 ? (
                              <img
                                src={
                                  !item.original_url
                                    ? demo
                                    : item.original_url
                                }
                                alt=""
                                className={classes.stepImage}
                              />
                            ) : (
                              <video
                                style={{ objectFit: "cover" }}
                                src={item.original_url}
                                className={classes.stepImage}
                              />
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  ) : (
                    <Box className={classes.stepImageDefault}>
                      <img
                        src={
                          isVideo && isVideo === "0" && projectImage
                            ? stepImage
                            : demo
                        }
                        alt="callback"
                        className={classes.stepAvatar}
                      />
                    </Box>
                  )}
                  <Box className={classes.voiceSelect}>
                    <Grid>
                      {"*"} {t("Please select a proper voice for your video.")}
                    </Grid>
                    <Box display={"flex"}>
                      <Radio
                        name="gender"
                        required={true}
                        checked={selectedValue === "male"}
                        onChange={handleChangeGender}
                        onClick={(e) => {
                          window.localStorage.setItem("gender", e.target.value);
                          setFieldValue("gender", e.target.value);
                          if (window.localStorage.getItem("language")) {
                            if (
                              window.localStorage.getItem("language") ===
                              "en-US"
                            ) {
                              setVoiceId("Matthew");
                            } else if (
                              window.localStorage.getItem("language") === "ru"
                            ) {
                              setVoiceId("Maxim");
                            } else if (
                              window.localStorage.getItem("language") ===
                              "es-ES"
                            ) {
                              setVoiceId("Miguel");
                            } else {
                              setVoiceId("Volodymyr");
                            }
                          } else {
                            if (language === "en-US") {
                              setVoiceId("Matthew");
                            } else if (language === "ru") {
                              setVoiceId("Maxim");
                            } else if (language === "es-ES") {
                              setVoiceId("Miguel");
                            } else {
                              setVoiceId("Volodymyr");
                            }
                          }
                        }}
                        value="male"
                      />
                      <Grid className={classes.voice}>{t("Male")}</Grid>
                    </Box>
                    <Box display={"flex"}>
                      <Radio
                        name="gender"
                        required={true}
                        checked={selectedValue === "female"}
                        onChange={handleChangeGender}
                        onClick={(e) => {
                          window.localStorage.setItem("gender", e.target.value);
                          setFieldValue("gender", e.target.value);
                          if (window.localStorage.getItem("language")) {
                            if (
                              window.localStorage.getItem("language") ===
                              "en-US"
                            ) {
                              setVoiceId("Joanna");
                            } else if (
                              window.localStorage.getItem("language") === "ru"
                            ) {
                              setVoiceId("Tatyana");
                            } else if (
                              window.localStorage.getItem("language") ===
                              "es-ES"
                            ) {
                              setVoiceId("Lupe");
                            } else {
                              setVoiceId("Natalia");
                            }
                          } else {
                            if (language === "en-US") {
                              setVoiceId("Joanna");
                            } else if (language === "ru") {
                              setVoiceId("Tatyana");
                            } else if (language === "es-ES") {
                              setVoiceId("Lupe");
                            } else {
                              setVoiceId("Natalia");
                            }
                          }
                        }}
                        value="female"
                      />
                      <Grid className={classes.voice}>{t("Female")}</Grid>
                    </Box>
                  </Box>
                  <Box marginTop={"20px"}>
                    <Grid>
                      {"*"}{" "}
                      {t(
                        "Please enter your email address. We will send you a link to your files, using this address"
                      )}
                    </Grid>
                    <TextField
                      // required={true}
                      id="email"
                      label={t("Email Address")}
                      name="email"
                      autoComplete="email"
                      style={{ marginTop: "15px" }}
                      value={values.email}
                      onChange={(event) => {
                        window.localStorage.setItem(
                          "email",
                          event.target.value
                        );
                        //  handleChange(values.email);
                        setFieldValue("email", event.target.value);
                      }}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Box>

                  <Box p={3} className={classes.stepButtons}>
                    <Link
                      to="/stepOne"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.button}
                        onClick={() => handleBack()}
                      >
                        {t("Prev Step")}
                      </Button>
                    </Link>

                    {saveButton && (
                      <Button
                        variant="contained"
                        style={{ marginLeft: "20px" }}
                        type="submit"
                        size="large"
                        onClick={() => {
                          handleSubmit(values.gender);
                          // onGetBusinessCard();
                          Object.values(values).every((el) => el !== "") &&
                            values.email.match(emailRegExp) &&
                            setSave(true);
                          Object.values(values).every((el) => el !== "") &&
                            values.email.match(emailRegExp) &&
                            setSaveButton(false);
                        }}
                        className={classes.button}
                      >
                        {t("Save")}
                      </Button>
                    )}

                    {save && (
                      <Link
                        to={!stepKeywords ? "/stepTwo" : "/stepThree"}
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <Button
                          variant="contained"
                          disabled={!stepKeywords}
                          size="large"
                          style={{ marginLeft: "20px" }}
                          className={classes.button}
                          onClick={() => {
                            fullPackageFunction();
                            handleNext();
                          }}
                        >
                          {t("Next Step")}
                        </Button>
                      </Link>
                    )}
                  </Box>
                </Box>
              </motion.div>
            </Form>
          )}
        </Formik>
      </Box>
    );
  }
  return <span></span>;
};

export default StepTwo;
