export const instructionSteps = [
  {
    stepNumber: "Step One",
    stepText:
      "You will be asked if you want to use your own Photo or Business Card Image. If you decide to use your own video, then you will be given an opportunity to upload it hear. If you do not have one, then you can use a default image we provide.",
  },
  {
    stepNumber: "Step Two",
    stepText:
      "You will be asked to answer 16 questions about your business. Please carefully read explanations and examples for each question. Note that by FTC regulations, you are not allowed to solicit money using this software.",
  },
  {
    stepNumber: "Step Three",
    stepText:
      "You will be given opportunity to edit an article about your business, as well as improve and expand a suggested keyword list to improve your SEO.",
  },
  {
    stepNumber: "Step Four",
    stepText:
      "Your video will be generated and you will be able to preview your creation. You will be given a chance to return and make corrections.",
  },
  {
    stepNumber: "Step Five",
    stepText:
      "Your newly created video will be published on the Startup Report YouTube channel. Links to your video, a business article, and a list of useful keywords will be sent to your email address provided in Step 2.",
  },
];

