import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { data } from "./data";

const useStyles = makeStyles({
  stepContent: {
    display: "flex",
    padding: "15px",
    "@media (max-width:780px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  stepNumber: {
    color: "#ffffff",
    background: "#4d555d",
    width: "150px",
    height: "90px",
    textAlign: "center",
    textTransform: "uppercase",
    padding: "15px",
    borderRadius: "40px 0px",
    overflow: "hidden",
    fontWeight: 600,
    "@media (max-width:780px)": {
      borderRadius: "30px 0px",
      padding: "8px",
      width: "130px",
      height: "70px",
    },
  },
  stepDescription: {
    width: "100%",
    padding: "10px 15px",
    whiteSpace: "pre-line",
  },
});

const StepsContent = () => {
  const classes = useStyles();

  const { t, ready } = useTranslation();

  if (ready) {
    return (
      <Box>
        {data.map((item, index) =>
        (<Box className={classes.stepContent} key={index}>
          <Grid className={classes.stepNumber}>
            <hr />
            {t(item.step)}
            <hr />
          </Grid>
          <Grid className={classes.stepDescription}>
            {t(item.description)}
          </Grid>
        </Box>)
        )}
      </Box>
    );
  }
  return <span></span>;
};

export default StepsContent;
