import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Box, Button } from "@mui/material";
import paypal from "../../assets/images/paypal.png";

const PayPal = ({ setStepForm, setIsLoggedIn }) => {
  useEffect(() => {
    setStepForm(false);
  }, [setStepForm]);

  const navigate = useNavigate();

  const userId = useSelector(state => state?.userReducer?.user_id);
  
  const deleteUser = () =>
  fetch(`https://thestartupreport.net/amember/api/users/${userId}`, {
    method: 'DELETE',
    headers: {
      "X-API-Key": "0AQuz2cRy6AaHG88gJGC",
      "content-type": "application/json;charset=utf-8",
    },
  });
  
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        sx={{ height: "80px", position: "sticky" }}
      >
        <h1>PayPal</h1>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        sx={{ height: "250px", marginBottom: "30px" }}
      >
        <img src={paypal} alt="paypal" />
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <PayPalScriptProvider
          options={{
            "client-id":
              "AdWlhPqA6WrOkeaFT8VUKYnrQmtC00fBThI-eHNa0E67MLQBE6QgM8UXQie4VYL0c0ZdfjEMGBoB5lHu",
          }}
        >
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: window.localStorage.getItem("amount") || "0.01",
                    },
                  },
                ],
              });
            }}
            onApprove={(data, actions) => {
              return actions.order.capture().then((details) => {
                const name = details.payer.name.given_name;
                alert(`Transaction completed by ${name}`);
                setTimeout(() => {
                  navigate("/");
                }, 200);
              });
            }}
          />
        </PayPalScriptProvider>
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          style={{
            color: "#fff",
            backgroundColor: "#D8571C",
            marginTop: "10px",
          }}
          onClick={() => {
            deleteUser();
            setIsLoggedIn(false);
            navigate("/");
          }}
        >
          CANCEL REGISTRATION
        </Button>
      </Box>
    </>
  );
};

export default PayPal;
