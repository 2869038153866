import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    step: {
      textTransform: "uppercase",
    },
    stepAvatar: {
      width: "400px",
      height: "250px",
      marginTop: "30px",
      border: "5px solid #96b0c1",
      borderRadius: "10px",
      "@media (max-width:780px)": {
        width: "250px",
        height: "150px",
      },
    },
    imageBlock: {
      display: "flex",
      justifyContent: "space-evenly",

      "@media (max-width:970px)": {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    stepImageBox: {
      width: "250px",
      height: "165px",
      border: "5px solid #96b0c1",
      borderRadius: "10px",
      "@media (max-width:1350px)": {
        width: "250px",
        height: "160px",
      },
      "@media (max-width:1220px)": {
        width: "250px",
        height: "160px",
      },
      "@media (max-width:1154px)": {
        width: "250px",
        height: "160px",
      },
      "@media (max-width:780px)": {
        width: "250px",
        height: "170px",
      },
    },
    
    description: {
      flexDirection: "row",
      "@media (max-width:900px)": {
        flexDirection: "column",
      },
    },
    
    stepImage: {
      width: "100%",
      height: "100%",
    },
  
    main: {
      display: "flex",
      marginTop: "20px",
      flexDirection: "column",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      marginTop: "20px",
    },
    contentText: {
      paddingTop: "15px",
    },
    buttons: {
      display: "flex",
      justifyContent: "center",
      marginTop: "80px",
      "@media (max-width:780px)": {
        flexDirection: "column",
        alignItems: "center",
        marginTop: "0px",
      },
    },
    stepButton: {
      "@media (max-width:780px)": {
        fontSize: "10px !important",
        marginTop: "20px !important",
        marginLeft: "0px !important",
        width: "200px",
      },
    },
  });