export const data = [
  // { text: "Please, click to this url:" },
  // { text: "for download of your video!" },
  // { text: "Or copy and paste this url in yor browser" },
  // { text: "This link will be available during 72 hours." },
  { text: "Your video: ", error: "Video download unavailable in demo mode" },
  { text: "Your audio:", error: "Audio download unavailable in demo mode" },
  { text: "Your text:" },
  { text: "Your keywords:" },
  { text: "Links will be available 72 hours." },
  { text: "We sent the email with links to download your files." },
]