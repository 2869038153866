import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const accessToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJUZW5uaXNMaXZlIiwibmFtZSI6ImFwaV90b2tlbiIsImlhdCI6MTYwNTU1NTE1MywiZXhwIjoxODkzNDU1OTk5fQ.rvYxT8eIPbitqrMEnCImJPaOig-BsfqF6s6tkDJiCOQ";

const initialState = {
  content: [],
  error: null,
  isLoading: false,
  token: `Bearer ${accessToken}`,
};

export const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

export const uploadNewContent = createAsyncThunk(
  "content/fetchContent",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        "https://thestartupreport.net/api/v1.0/upload.php",
        data,
        config
      );
      if (response.status === 200) {
        localStorage.setItem("token", accessToken);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const contentUploadSlice = createSlice({
  name: "content",
  initialState,
  reducers: {},
  extraReducers: {
    [uploadNewContent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.content = state.content.concat(action.payload.result);
      window.localStorage.setItem(
        "image",
        action.payload.result?.[0]?.original_url
      );
      window.localStorage.setItem(
        "video",
        action.payload.result?.[0]?.original_url
      );
      window.localStorage.setItem(
        "is_video",
        action.payload.result?.[0]?.is_video
      );
      window.localStorage.setItem(
        "content",
        JSON.stringify(action.payload.result)
      );
    },
    [uploadNewContent.pending]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [uploadNewContent.rejected]: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
  },
});

export const { contentAdded } = contentUploadSlice.actions;

export default contentUploadSlice.reducer;
