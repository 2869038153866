import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Typography, TextField, Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import bg from "../../assets/images/header.png";
import demo from "../../assets/images/demo.jpg";
import { makeStyles } from "@mui/styles";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { data } from "./data";
import { addImagetexts } from "../../redux/slices/imagetextSlice";
import { doVideo } from "../../redux/slices/videoSlice";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles({
  step: {
    textTransform: "uppercase",
  },
  stepAvatar: {
    width: "400px",
    height: "250px",
    marginTop: "30px",
    border: "5px solid #96b0c1",
    borderRadius: "10px",
    "@media (max-width:780px)": {
      width: "250px",
      height: "150px",
    },
  },
  imageBlock: {
    display: "flex",
    justifyContent: "space-evenly",
    "@media (max-width:970px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  stepImageBox: {
    width: "400px",
    height: "250px",
    marginTop: "30px",
    border: "5px solid #96b0c1",
    borderRadius: "10px",
    "@media (max-width:1350px)": {
      width: "350px",
      height: "250px",
    },
    "@media (max-width:1220px)": {
      width: "300px",
      height: "250px",
    },
    "@media (max-width:1154px)": {
      width: "280px",
      height: "235px",
    },
    "@media (max-width:780px)": {
      width: "250px",
      height: "170px",
    },
  },
  stepImage: {
    width: "100%",
    height: "100%",
  },

  main: {
    display: "flex",
    marginTop: "20px",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
  },
  contentText: {
    paddingTop: "15px",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginTop: "80px",
    "@media (max-width:780px)": {
      flexDirection: "column",
      alignItems: "center",
      marginTop: "0px",
    },
  },
  stepButton: {
    "@media (max-width:780px)": {
      fontSize: "10px !important",
      marginTop: "20px !important",
      marginLeft: "0px !important",
      width: "200px",
    },
  },
});

const StepThreeFullPackage = ({
  handleNext,
  handleBack,
  fullPackage,
  isLoggedIn,
  stepImage,
  setStepImage,
  isVideo,
  setIsVideo,
  fileNames,
}) => {
  const classes = useStyles();

  useEffect(() => {
    window.localStorage.getItem("keywords");
  }, []);

  useEffect(() => {
    window.localStorage.setItem("fullPackage", fullPackage);
  }, [fullPackage]);

  useEffect(() => {
    JSON.parse(window.localStorage.getItem("project_id"));
  }, []);

  const { i18n, t, ready } = useTranslation();

  const dispatch = useDispatch();

  const localData = JSON.parse(localStorage.getItem("content"));
  const projectImage = localStorage.getItem("image");

  const stepVideoId = useSelector(
    (state) => state?.contentUploadReducer?.content?.[0]?.id
  );

  const cardId = useSelector(
    (state) => state?.doVideoReducer?.videos?.result?.[0]?.id
  );

  const stepItemOrder = useSelector(
    (state) => state?.contentUploadReducer?.content?.[0]?.item_order
  );

  const updateImagetexts = (values) => {
    const body = {
      action: "update_imagetexts",
      data: [
        {
          id: fileNames.length !== 0 ? stepVideoId : cardId,
          item_order: stepItemOrder,
          textarea: values.description || window.localStorage.getItem("text"),
          subtitles: values.subtitles,
        },
      ],
    };
    dispatch(addImagetexts(body));
  };

  const doNewVideo = () => {
    const body = {
      action: "do_video",
      project_id: JSON.parse(window.localStorage.getItem("project_id")),
    };

    dispatch(doVideo(body));
  };

  useEffect(() => {
    window.localStorage.getItem("subtitles");
  }, []);

  useEffect(() => {
    window.localStorage.getItem("text");
  }, []);

  useEffect(() => {
    setStepImage(window.localStorage.getItem("image"));
  }, [setStepImage]);

  useEffect(() => {
    setIsVideo(window.localStorage.getItem("is_video"));
  }, [setIsVideo]);

  const formValidation = Yup.object({
    keywords: Yup.string()
      .matches(/^#/, "start with #")
      .min(3, "Must be more then 3 character")
      .max(150)
      .required(() => i18n.t("Required")),
  });

  if (ready) {
    return (
      <Box>
        <img
          src={bg}
          alt="header"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
        {!isLoggedIn && (
          <Alert severity="warning"> {t("You are in the demo mode")}</Alert>
        )}
        <Formik
          initialValues={{
            amember_user_id: 12345,
            description: fullPackage ? window.localStorage.getItem("text") : "",
            keywords: window.localStorage.getItem("keywords"),
            subtitles: window.localStorage.getItem("subtitles") || "0",
          }}
          onSubmit={(values) => {
            updateImagetexts(values);
          }}
          validationSchema={formValidation}
        >
          {({ values, touched, errors, handleSubmit, setFieldValue }) => (
            <Form>
              <motion.div
                initial={{ width: 0 }}
                animate={{
                  width: "100%",
                  transition: {
                    transitionProperty: "max-height",
                    transitionDuration: "0.5s",
                    transitionTimingFunction: "ease-in",
                    transitionDelay: "0s",
                  },
                }}
              >
                <Box p={4}>
                  <hr />
                  <Typography className={classes.step} variant="h5">
                    {t("Step Three")}
                  </Typography>
                  <hr />

                  {data.map((item) =>
                    item.info.map((text, index) => (
                      <Typography paddingTop={"10px"} key={index}>
                        {t(text)}
                      </Typography>
                    ))
                  )}

                  <Box className={classes.main}>
                    <Typography variant="h6">{t("Images")}</Typography>
                    {localData ? (
                      <Box
                        className={classes.imageBlock}
                        display={"flex"}
                        justifyContent={"space-evenly"}
                        flexWrap={"wrap"}
                      >
                        {localData.map((item, index) => {
                          return (
                            <Box key={index} className={classes.stepImageBox}>
                              {isVideo && item.is_video === 0 ? (
                                <img
                                  src={
                                    item.original_url ||
                                    demo
                                  }
                                  alt=""
                                  className={classes.stepImage}
                                />
                              ) : (
                                <video
                                  style={{ objectFit: "cover" }}
                                  src={item.original_url}
                                  className={classes.stepImage}
                                />
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                    ) : (
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        flexWrap={"wrap"}
                      >
                        <img
                          src={
                            isVideo && isVideo === "0" && projectImage
                              ? stepImage
                              : demo
                          }
                          alt="callback"
                          className={classes.stepAvatar}
                        />
                      </Box>
                    )}
                    <Box className={classes.content}>
                      <Typography variant="h6" className={classes.contentText}>
                        {t("Please edit your text")}
                      </Typography>
                      <TextField
                        id="outlined-multiline-static"
                        label={t("Description")}
                        multiline
                        rows={5}
                        style={{ maxWidth: "600px", marginTop: "10px" }}
                        value={values.description}
                        onChange={(event) => {
                          window.localStorage.setItem(
                            "text",
                            event.target.value
                          );
                          setFieldValue("description", event.target.value);
                        }}
                      />
                    </Box>
                    <Typography variant="h6" className={classes.contentText}>
                      {t("Subtitles")}
                    </Typography>
                    <Box display={"flex"} justifyContent={"flex-start"}>
                      <Checkbox
                        disableRipple
                        value={values.subtitles}
                        checked={values.subtitles === "1"}
                        onClick={() => {
                          setFieldValue(
                            "subtitles",
                            values.subtitles === "0" ? "1" : "0"
                          );
                          window.localStorage.setItem(
                            "subtitles",
                            values.subtitles === "0" ? "1" : "0"
                          );
                        }}
                      />
                    </Box>
                  </Box>

                  <Box marginTop={"15px"}>
                    <Typography variant="h6" className={classes.contentText}>
                      {t("Keywords")}
                    </Typography>
                    <TextField
                      required
                      id="text"
                      label={t("Enter The Keywords")}
                      name="keywords"
                      multiline
                      autoComplete="off"
                      value={values.keywords}
                      onChange={(event) => {
                        window.localStorage.setItem(
                          "keywords",
                          event.target.value
                        );
                        setFieldValue("keywords", event.target.value);
                      }}
                      error={Boolean(touched.keywords && errors.keywords)}
                      helperText={touched.keywords && errors.keywords}
                      style={{ marginTop: "15px" }}
                    />
                  </Box>

                  <Box p={3} className={classes.buttons} paddingBottom={"0px"}>
                    <Link
                      to={fullPackage ? "/stepTwoFullPackage" : "/stepTwo"}
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.stepButton}
                        style={{ fontSize: "10px" }}
                        onClick={() => {
                          handleBack();
                          window.scrollTo(0, 0);
                        }}
                      >
                        {t("Prev Step")}
                      </Button>
                    </Link>

                    <Link
                      to={
                        values.keywords && Object.keys(errors).length === 0
                          ? "/stepFour"
                          : "/stepThreeFullPackage"
                      }
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      <Button
                        required
                        variant="contained"
                        size="large"
                        type="submit"
                        className={classes.stepButton}
                        style={{ marginLeft: "20px", fontSize: "10px" }}
                        onClick={() => {
                          handleSubmit();
                          handleNext();
                          setTimeout(() => {
                            doNewVideo();
                          }, 1000);
                        }}
                      >
                        {isLoggedIn
                          ? t("Next Step")
                          : t("Next step without registration")}
                      </Button>
                    </Link>

                    {!isLoggedIn && (
                      <Link
                        to="/login"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <Button
                          variant="contained"
                          size="large"
                          className={classes.stepButton}
                          style={{ marginLeft: "20px", fontSize: "10px" }}
                        >
                          {t("Singn up and Continue")}
                        </Button>
                      </Link>
                    )}
                  </Box>
                </Box>
              </motion.div>
            </Form>
          )}
        </Formik>
      </Box>
    );
  }
  return <span></span>;
};

export default StepThreeFullPackage;
