import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import { uploadW9 } from "../../redux/slices/w9UploadSlice";
import { Button, Typography, Box, Avatar, Grid } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const SignUpW9 = ({ setStepForm }) => {
  useEffect(() => {
    setStepForm(false);
  }, [setStepForm]);

  useEffect(() => {
    window.onpopstate = () => {
      setStepForm(false);
    };
  }, [setStepForm]);

  const [w9, setW9] = useState([]);
  const [message, setMessage] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, ready } = useTranslation();

   const amemberUserId = useSelector((state) => state?.userReducer?.user_id);

  const w9Content = useSelector((state) => state?.w9UploadReducer?.content);

  const onSaveW9 = () => {
    const data = new FormData();
    data.append("action", "upload_w9_form");
    data.append("amember_user_id", amemberUserId);
    w9.forEach((file, index) => data.append(`file[${index}]`, file));
    dispatch(uploadW9(data));
  };

  if (ready) {
    return (
      <Box
        display={"flex"}
        justifycontent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        sx={{ mt: 10, mb: 8 }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Formik
          initialValues={{
            action: "upload_w9_form",
            amember_user_id: amemberUserId,
          }}
          onSubmit={(values) => {
            onSaveW9(values);
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Form>
           
              <Grid textAlign={"center"} marginTop={"10px"}>
                {t("Drag and drop the filled from here")}
              </Grid>

              {message && (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "15px",
                    color: "red",
                  }}
                >
                  <Typography>
                    You are not registered in our affiliate program!
                  </Typography>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
              >
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    setW9(acceptedFiles);
                    handleSubmit(acceptedFiles[0]);
                    setFieldValue("amember_user_id", amemberUserId);
                    setMessage(false);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <Grid>
                        {t("Drag'n'drop files, or click to select files")}
                      </Grid>
                    </Box>
                  )}
                </Dropzone>
              </Grid>
            </Form>
          )}
        </Formik>
        <Button
          type="submit"
          variant="contained"
          onClick={() =>
            w9Content.length < 1 ? setMessage(true) : navigate("/PayPal")
          }
        >
          CONTINUE
        </Button>
      </Box>
    );
  }
  return <span></span>;
};

export default SignUpW9;
