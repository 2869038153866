import React from "react";
import { Box } from "@mui/system";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loader = () => {
  return (
    <Box display={"flex"} justifyContent={"center"}>
      <CircularProgress style={{ color: "#D8571C" }} />
    </Box>
  );
};

export default Loader;
