export const data = [
    { text: "This project was not be enabled without big preparation, chain of serendipity events and people, who was involved in on different milestones." },
    { text: "I would like to thanks Mrs. Loral Logemeier. Without her teaching I was not be able to open and talk with people." },
    { text: "This project will literally not happened without Mr. Bill Walsh. I am alumni of his flagship Rainmaker program, and going through this program helped me to organize and priorities my work. It was Mr. Walsh, who invited me to Pick Potential event in March 2016, where I met young leady Ms. Maite Ballesteros." },
    { text: "Ms. Ballesteros is freelance writer, journalist with experience work in TV. Mite share with me her dream to start new business. An idea of this project come to life, when we brainstorm with Maite, who may be her customers. While Mite sought opportunity far offsite of her education and practice and it was not practical to start, but we find that she might to visit startups, take an interview and post it on Facebook and YouTube." },
    { text: "Mr. Walsh advise me to take Mite as my mentoring student. We start work together and as result The Startup Video Sale Sheet Builder project appear to the world." },
    { text: "This project got serious boost after I visited National Hardware Show in Las Vegas moth after. Using the best-selling technic ATA (Ask-Tell-Ask) I learn from Mrs. Loral Logemeier I asked each of 100 inventor’s families assembled in the same room about their businesses and their advertising needs. I was not be able to sell them anything, but learn that demand for this type of advertising for small businesses is up to 90%" },
    { text: "Ms. Ballesteros decide to pursue other opportunity after 3 month, but I was not ready to quit. I was not be able to go to potential clients a take an interview, but Mr. Zeke Rich advise me to make a SaaS –Software as a service to automate a video creation and he put together a working demo. My son Daniel Aretskin wrote Intro and Exit bells for the video." },
    { text: "By December of 2017 the software was in a good shape to demonstrate, but still not ready for sale. I slowly prepare it to March 18, 2018 – second anniversary of it beginning. Then my server crashed, the application crashed and I was no longer was able to produce anything." },
    { text: "I was recommended to look for help on Upworks.com, were find Mr. Igor Korolev – very good programmer from Russia. With his help my amateur application get shape of a real product." },
    { text: "To be continued…" },
    { text: "7/4/2018" },
    { text: "Mark Aretskin" },
  ]
  