import React, { useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useTranslation } from "react-i18next";

const StepForm = ({ activeStep, setActiveStep, setStepForm }) => {
  useEffect(() => {
    switch (window.location.pathname) {
      case "/stepOne":
        setActiveStep(0);
        break;
      case "/stepTwo":
      case "/stepTwoFullPackage":
        setActiveStep(1);
        break;
      case "/stepThree":
      case "/stepThreeFullPackage":
        setActiveStep(2);
        break;
      case "/stepFour":
        setActiveStep(3);
        break;
      default:
        break;
    }
  }, [setActiveStep]);

  useEffect(() => {
    window.onpopstate = () => {
      switch (window.location.pathname) {
        case "/stepOne":
          setActiveStep(0);
          break;
        case "/stepTwo":
        case "/stepTwoFullPackage":
          setActiveStep(1);
          break;
        case "/stepThree":
        case "/stepThreeFullPackage":
          setActiveStep(2);
          break;
        case "/stepFour":
          setActiveStep(3);
          break;
        default:
          break;
      }
      setStepForm(true);
    };
  }, [setActiveStep, activeStep, setStepForm]);

  const { t, ready } = useTranslation();

  const steps = ["Step One", "Step Two", "Step Three", "Step Four"];

  if (ready) {
    return (
      <Stepper activeStep={activeStep} sx={{ py: 2 }} alternativeLabel>
        {steps.map((step) => (
          <Step key={step}>
            <StepLabel>{t(step)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }
  return <span></span>;
};

export default StepForm;
