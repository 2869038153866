import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import bg from "../../assets/images/header.png";
import StepsContent from "../../Components/StepsContent";
import { useTranslation } from "react-i18next";
import HowItWork from "../HowItWorkPage/HowItWork";
import StartButton from "../../Components/StartButton/StartButton";
import { socialNetworks } from "./utils";
import Agreement from "../../Components/Agreement";

const useStyles = makeStyles({
  contentWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "30px",
    "@media (max-width: 780px)": {
      display: "block",
    },
  },
});

const Home = ({ setStepForm, isLoggedIn }) => {
  const [agreement, setAgreement] = useState(false);
  const { t, ready } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    setStepForm(false);
  }, [setStepForm]);

  useEffect(() => {
    localStorage.setItem("do_business_card", "");
  }, []);

  useEffect(() => {
    if(isLoggedIn) {
      setAgreement(true);
    } else {
      setAgreement(false)
   } 
  }, [isLoggedIn]);
  
  if (ready) {
    return (
      <Box>
        <img
          src={bg}
          alt="header"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
        <Box p={4} paddingBottom={"0px"}>
          <Typography align="center" variant="h4" style={{ fontWeight: 700 }}>
            {t("Welcome to The Startup Video Press Release Builder!")}
          </Typography>
          <Grid display={"flex"} justifyContent={"center"}>
            <Typography
              align="center"
              variant="h6"
              style={{ marginTop: "10px", width: "80%" }}
            >
              {t(
                "Create your article for printed and social media, an audio jingle for radio and podcast advertising and narrated video for YouTube, Instagram or TikTok in under 10 minutes in 5 simple steps."
              )}
            </Typography>
          </Grid>
          <Grid display={"flex"} justifyContent={"center"} marginTop={2}>
            {socialNetworks.map((el, index) => (
              <a key={index} href={el.link} target="blank">
                <img
                  src={el.icon}
                  alt=""
                  width={30}
                  height={30}
                  style={{ marginLeft: index !== 0 ? 8 : 0, cursor: "pointer" }}
                />
              </a>
            ))}
          </Grid>
          <Grid p={3} paddingBottom={"0px"}>
            <StartButton agreement={agreement} />
          </Grid>
          {!isLoggedIn && <Agreement agreement={agreement} setAgreement={setAgreement} />}
          <Grid className={classes.contentWrapper}>
            <StepsContent />
            <HowItWork setStepForm={setStepForm} />
          </Grid>
          <Grid p={3} paddingBottom={"5px"}>
            <StartButton agreement={agreement} />
          </Grid>
        </Box>
      </Box>
    );
  }
  return <span></span>;
};
export default Home;
