import facebook from "../../assets/images/facebook.png";
import youtube from "../../assets/images/youtube.png";
import linkedIn from "../../assets/images/linkedIn.png";
import instagram from "../../assets/images/instagram.png";
import pinterest from "../../assets/images/pinterest.png";
import tiktok from "../../assets/images/tiktok.png";

export const socialNetworks = [
  { icon: facebook, link: "https://www.facebook.com/" },
  { icon: youtube, link: "https://www.youtube.com/" },
  { icon: linkedIn, link: "https://www.linkedin.com/" },
  { icon: instagram, link: "https://www.instagram.com/" },
  { icon: pinterest, link: "https://www.pinterest.com/" },
  { icon: tiktok, link: "https://www.tiktok.com/" },
];
