import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const accessToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJUZW5uaXNMaXZlIiwibmFtZSI6ImFwaV90b2tlbiIsImlhdCI6MTYwNTU1NTE1MywiZXhwIjoxODkzNDU1OTk5fQ.rvYxT8eIPbitqrMEnCImJPaOig-BsfqF6s6tkDJiCOQ";

const config = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
    "X-API-Key": "0AQuz2cRy6AaHG88gJGC",
    "content-type": "application/x-www-form-urlencoded;charset=utf-8",
  },
};

export const signupUser = createAsyncThunk(
  "users/signup",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `https://thestartupreport.net/amember/api/users`,
        data,
        config
      );
      if (response.status === 200) {
        localStorage.setItem("token", accessToken);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  "users/login",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://thestartupreport.net/amember/api/check-access/by-login-pass?&login=${data.login}&pass=${data.pass}`,
        config
      );
      if (response.status === 200) {
        localStorage.setItem("token", accessToken);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk();

export const userSlice = createSlice({
  name: "user",
  initialState: {
    username: window.localStorage.getItem("username") || "",
    user_id: window.localStorage.getItem("user_id") || "",
    email: "",
    login: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    agreement: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [signupUser.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
      const [user] = action?.payload;
      state.email = user?.email;
      state.user_id = user?.user_id;
      state.username = `${user?.name_f} ${user?.name_l}`;
      window.localStorage.setItem("username", state.username);
      window.localStorage.setItem("user_id", state.user_id);
      return state;
    },
    [signupUser.pending]: (state) => {
      state.isFetching = true;
    },
    [signupUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.email = action.payload?.email;
      state.login = action.payload?.login;
      state.username = action.payload?.name;
      state.pass = action.payload?.pass;
      state.isFetching = false;
      state.isSuccess = true;
      window.localStorage.setItem("username", action.payload.name);
      return state;
    },
    [loginUser.rejected]: (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.payload?.message;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [logoutUser.rejected]: (state) => {
      state.email = "";
      state.login = "";
      state.pass = "";
      state.username = "";
      localStorage.clear();
    },
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;

export default userSlice.reducer;
