export const handleCleanStorage = () => {
    window.localStorage.setItem("salutation", "");
    window.localStorage.setItem("name", "");
    window.localStorage.setItem("title", "");
    window.localStorage.setItem("company_name", "");
    window.localStorage.setItem("industry", "");
    window.localStorage.setItem("activity", "");
    window.localStorage.setItem("companyType", "");
    window.localStorage.setItem("customers", "");
    window.localStorage.setItem("description", "");
    window.localStorage.setItem("accomplishments", "");
    window.localStorage.setItem("goal", "");
    window.localStorage.setItem("yourNeeds", "");
    window.localStorage.setItem("tel", "");
    window.localStorage.setItem("website", "");
    window.localStorage.setItem("email", "");
    window.localStorage.setItem("gender", "");
    window.localStorage.setItem("subtitles", "0");
    window.localStorage.setItem("project_id", null);
    window.localStorage.setItem("text", "");
    window.localStorage.setItem("insert_imagetexts", []);
    window.localStorage.setItem("mode", null);
    window.localStorage.setItem("acceptedFiles", "");
    window.localStorage.setItem("month", "");
    window.localStorage.setItem("year", "");
    window.localStorage.removeItem("image");
    window.localStorage.removeItem("video");
    window.localStorage.removeItem("doVideo");
    window.localStorage.removeItem("content");
  };