import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import NavItem from "../NavItem/NavItem";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import LangMenu from "../LangMenu/LangMenu";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { loginUser } from "../../redux/slices/userSlice";

const useStyles = makeStyles({
  mobileNavigation: {
    position: "fixed",
    backgroundColor: "#131313",
    height: "100vh",
    width: "100%",
    zIndex: 1000,
    color: "#fff",
  },
  navContent: {
    paddingTop: "60px",
    paddingLeft: "45px",
    fontSize: "26px",
    "@media (orientation: landscape)": {
      paddingTop: "30px",
    },
  },
  navLink: {
    textDecoration: "none",
    color: "#fff",
  },
  navItem: {
    paddingTop: "30px",
    "@media (orientation: landscape)": {
      paddingTop: "15px",
    },
  },
  langMenu: {
    paddingTop: "30px",
    "@media (orientation: landscape)": {
      paddingTop: "15px",
    },
  },
  closeIcon: {
    float: "right",
    marginTop: "20px",
    marginRight: "20px",
  },
  Buttons: {
    width: "100%",
    height: "80px",
    marginTop: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (orientation: landscape)": {
      height: "40px",
    },
  },
  logInButton: {
    color: "#fff !important",
    border: "1px solid #fff !important",
    "@media (orientation: landscape)": {
      height: "30px",
    },
  },
  signUpButton: {
    height: "40px",
    color: "#FFF !important",
    background: "#262627 !important",
    marginLeft: "15px !important",
    "@media (orientation: landscape)": {
      height: "30px",
    },
  },
});

const MobileNavigation = ({ open, setOpenNav, isLoggedIn, setIsLoggedIn, setLanguage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loginUser());
  }, [dispatch]);

  useEffect(() => {
    setIsLoggedIn(JSON.parse(window.localStorage.getItem("isLoggedIn")));
  }, [setIsLoggedIn]);

  useEffect(() => {
    window.localStorage.getItem("username");
  }, []);

  const isLoggedOutFunction = () => {
    localStorage.setItem("isLoggedIn", false);
    setIsLoggedIn({ isLoggedIn: localStorage.getItem("isLoggedIn") });
  };

  const { t, ready } = useTranslation();

  const user = useSelector((state) => state.userReducer);

  if (ready) {
    return (
      <motion.div
        className={classes.mobileNavigation}
        initial={{ width: 0 }}
        animate={{
          width: "100%",
          transition: {
            transitionProperty: "max-height",
            transitionDuration: "0.5s",
            transitionTimingFunction: "ease-in",
            transitionDelay: "0s",
          },
        }}
        exit={{
          width: 0,
          transition: {
            transitionProperty: "max-height",
            transitionDuration: "0.5s",
            transitionTimingFunction: "ease-in",
            transitionDelay: "0s",
          },
        }}
      >
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => {
            setOpenNav(false);
          }}
        />
        <Box className={classes.navContent}>
          <Grid
            className={classes.navItem}
            onClick={() => {
              setOpenNav(false);
            }}
          >
            <Link to="/" className={classes.navLink}>
              <NavItem open={open} title={t("Home")}
                onClick={() => {
                  navigate("/");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1500);
                }}
              />
            </Link>
          </Grid>
          <Grid
            className={classes.navItem}
            onClick={() => {
              setOpenNav(false);
            }}
          >
            <Link to="/about" className={classes.navLink}>
              <NavItem open={open} title={t("About Project")} />
            </Link>
          </Grid>

          <Grid className={classes.langMenu}>
            <LangMenu open={true} setLanguage={setLanguage} />
          </Grid>
        </Box>

        {isLoggedIn ? (
          <Box
            className={classes.Buttons}
            display={"flex"}
            alignItems={"center"}
          >
            <Link
              to="/userPage"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <Grid
                onClick={() => {
                  setOpenNav(false);
                }}
              >
                {user.username}
              </Grid>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                className={classes.signUpButton}
                onClick={() => {
                  isLoggedOutFunction();
                  setIsLoggedIn(false);
                }}
              >
                {t("Log out")}
              </Button>
            </Link>
          </Box>
        ) : (
          <Box className={classes.Buttons}>
            <Link to="/login" style={{ textDecoration: "none" }}>
              <Button
                className={classes.logInButton}
                onClick={() => {
                  setOpenNav(false);
                }}
              >
                {t("Log in")}
              </Button>
            </Link>
            {/* <Link to="/signUp" style={{ textDecoration: "none" }}>
              <Button
                className={classes.signUpButton}
                onClick={() => {
                  setOpenNav(false);
                }}
              >
                {t("Sign up")}
              </Button>
            </Link> */}
          </Box>
        )}
      </motion.div>
    );
  }
  return <span></span>;
};

export default MobileNavigation;
