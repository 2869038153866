export const textData = [
    "You have 2 options.",
    "",
    "Option 1:",
    "Craft your standout video business card by uploading your own photo, business card image, or video  here.",
    "No content? No worries—use our default image filled with your details.",
    "Next, delve into personalization with 16 tailored questions.",
    "",
    "Option 2:",
    "Opting for a snappy advertising video?",
    "Upload your image or video here; we won't use a default image.",
    "Then, add your unique touch with freeform text.",
    "(You may upload up to 50 MB)"
]