import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const accessToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJUZW5uaXNMaXZlIiwibmFtZSI6ImFwaV90b2tlbiIsImlhdCI6MTYwNTU1NTE1MywiZXhwIjoxODkzNDU1OTk5fQ.rvYxT8eIPbitqrMEnCImJPaOig-BsfqF6s6tkDJiCOQ";

const initialState = {
  videos: [],
  status: null,
  error: null,
  token: `Bearer ${accessToken}`,
  isLoading: false,
};

const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

export const doVideo = createAsyncThunk(
  "video/fetchVideo",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `https://thestartupreport.net/api/v1.0/do_video.php?project_id=${data?.project_id}`,
        data,
        config
      );
      if (response.status === 200) {
        localStorage.setItem("token", accessToken);
        localStorage.setItem("doVideo", JSON.stringify(response.data.result));
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const doVideoSlice = createSlice({
  name: "doVideo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(doVideo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.videos = action.payload;
      })
      .addCase(doVideo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(doVideo.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { donewVideo } = doVideoSlice.actions;

export default doVideoSlice.reducer;
