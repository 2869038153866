import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const accessToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJUZW5uaXNMaXZlIiwibmFtZSI6ImFwaV90b2tlbiIsImlhdCI6MTYwNTU1NTE1MywiZXhwIjoxODkzNDU1OTk5fQ.rvYxT8eIPbitqrMEnCImJPaOig-BsfqF6s6tkDJiCOQ";

const config = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
    "X-API-Key": "0AQuz2cRy6AaHG88gJGC",
    "content-type": "application/x-www-form-urlencoded;charset=utf-8",
  },
};

export const usersPaymentData = createAsyncThunk(
  "users/payment",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        "https://thestartupreport.net/amember/api/invoices",
        //  `https://thestartupreport.net/amember/api/invoice-payments?_key=0AQuz2cRy6AaHG88gJGC`,
        data,
        config
      );
      if (response.status === 200) {
        localStorage.setItem("token", accessToken);
        return response.data;
      } else {
        console.log(thunkAPI.rejectWithValue(data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const paymentDataSlice = createSlice({
  name: "user",
  initialState: {
    amount: "",
    paysys_id: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [usersPaymentData.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
      const [user] = action.payload;
      state.amount = user?.first_total;
      state.paysys_id = user?.paysys_id;
      window.localStorage.setItem("amount", user?.first_total);
      return state;
    },
    [usersPaymentData.pending]: (state) => {
      state.isFetching = true;
    },
    [usersPaymentData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
  },
});

export const { clearState } = paymentDataSlice.actions;

export const userSelector = (state) => state.user;

export default paymentDataSlice.reducer;
