import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Checkbox, Box, Grid, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const Agreement = ({ agreement, setAgreement }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { t, ready } = useTranslation();

  if (ready) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <Checkbox
          sx={{ paddingLeft: "0px" }}
          disableRipple
          name="agreement"
          id="agreement"
            onClick={() => {
              setAgreement(!agreement);
            }}
        />
        <Typography>
          {t("I agree to")}{" "}
          <Link
            to=""
            onClick={handleOpen}
            style={{ textDecoration: "none", color: "#d8561c" }}
          >
            {t("the user agreement,")}
          </Link>{" "}
          {t("policy and processing of personal data")}
        </Typography>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <iframe
              src="../../terms_and_conditions.pdf"
              title="testPdf"
              width="100%"
              height="500px"
            />
          </Box>
        </Modal>
      </Grid>
    );
  }
  return <span></span>;
};

export default Agreement;
