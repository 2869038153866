import { configureStore, combineReducers } from "@reduxjs/toolkit";
import dataReducer from "./slices/dataSlice";
import userReducer from "./slices/userSlice";
import keywordsReducer from "./slices/keywordsSlice";
import imagetextsReducer from "./slices/imagetextSlice";
import contentUploadReducer from "./slices/contentUploadSlice";
import profileReducer from "./slices/profileSlice";
import doVideoReducer from "./slices/videoSlice";
import sendFinalEmailReducer  from "./slices/finalEmailSlice";
import paymentDataReducer from "./slices/paymentDataSlice";
import w9UploadReducer from "./slices/w9UploadSlice";
import businessCardReducer from "./slices/businessCardSlice";

const rootReducer = combineReducers({
  dataReducer,
  keywordsReducer,
  userReducer,
  imagetextsReducer,
  contentUploadReducer,
  profileReducer,
  doVideoReducer,
  sendFinalEmailReducer,
  paymentDataReducer,
  w9UploadReducer,
  businessCardReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
