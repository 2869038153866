import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    step: {
      textTransform: "uppercase",
    },
    videoPlayer: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "20px",
    },
    buttons: {
      display: "flex",
      justifyContent: "center",
      "@media (max-width:780px)": {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    firstStepButton: {
      "@media (max-width:780px)": {
        fontSize: "10px !important",
        width: "200px",
      },
    },
    stepButton: {
      "@media (max-width:780px)": {
        fontSize: "10px !important",
        marginTop: "20px !important",
        marginLeft: "0px !important",
        width: "200px",
      },
    },
  });