import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { loginUser } from "../../redux/slices/userSlice";
import * as Yup from "yup";

const Login = ({ setStepForm, setIsLoggedIn }) => {
  useEffect(() => {
    setStepForm(false);
  }, [setStepForm]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { i18n, t, ready } = useTranslation();

  const onSaveData = (values) => {
    const body = {
      action: "insert_project",
      login: values.login,
      pass: values.pass,
    };
    dispatch(loginUser(body))
      .then((result) => isLoggedInFunction(result))
      .catch((error) => isLoggedInFunction(error));
  };

  //console.log(document.referrer);

  const isLoggedInFunction = (data) => {
    if (data?.payload?.ok) {
      localStorage.setItem("isLoggedIn", true);
      setIsLoggedIn({ isLoggedIn: localStorage.getItem("isLoggedIn") });
      window.localStorage.setItem("username", data?.payload?.name);
      // navigate(-1);
      navigate("/");
    } else {
      localStorage.setItem("isLoggedIn", false);
      setIsLoggedIn(false);
      //  setIsLoggedIn({ isLoggedIn: localStorage.getItem("isLoggedIn") });
    }
  };

  const formValidation = Yup.object({
    login: Yup.string().required(() => i18n.t("Required")),
    pass: Yup.string().required(() => i18n.t("Required")),
  });

  if (ready) {
    return (
      <Container component="main" maxWidth="xs">
        <Formik
          initialValues={{ login: "", pass: "" }}
          onSubmit={(values) => {
            onSaveData(values);
          }}
          validationSchema={formValidation}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            touched,
            errors,
          }) => (
            <Form>
              <CssBaseline />
              <Box
                sx={{
                  height: "82vh",
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  "@media (orientation: landscape)": {
                    height: "110vh",
                    marginTop: 2,
                  },
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  {t("Log in")}
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="login"
                        label={t("Login name")}
                        value={values.login}
                        name="login"
                        autoComplete="login"
                        onChange={(event) => {
                          handleChange(values.login);
                          setFieldValue("login", event.target.value);
                        }}
                        error={Boolean(touched.login && errors.login)}
                        helperText={touched.login && errors.login}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="pass"
                        label={t("Password")}
                        value={values.pass}
                        type="password"
                        id="pass"
                        autoComplete="new-password"
                        onChange={(event) => {
                          handleChange(values.pass);
                          setFieldValue("pass", event.target.value);
                        }}
                        error={Boolean(touched.pass && errors.pass)}
                        helperText={touched.pass && errors.pass}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {t("Log in")}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Container>
    );
  }
  return <span></span>;
};

export default Login;
