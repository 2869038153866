import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  step: {
    textTransform: "uppercase",
  },
  stepAvatar: {
    width: "400px",
    height: "250px",
    marginTop: "30px",
    border: "5px solid #96b0c1",
    borderRadius: "10px",
    "@media (max-width:780px)": {
      width: "250px",
      height: "150px",
    },
  },
  voiceSelect: {
    marginTop: "30px",
  },
  voice: {
    marginTop: "13px",
  },
  button: {
    "@media (max-width:780px)": {
      fontSize: "10px !important",
    },
  },
  imageBlock: {
    display: "flex",
    justifyContent: "space-evenly",
    "@media (max-width:970px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  stepImageBox: {
    width: "400px",
    height: "250px",
    marginTop: "30px",
    border: "5px solid #96b0c1",
    borderRadius: "10px",
    "@media (max-width:1350px)": {
      width: "350px",
      height: "250px",
    },
    "@media (max-width:1220px)": {
      width: "300px",
      height: "250px",
    },
    "@media (max-width:1154px)": {
      width: "280px",
      height: "235px",
    },
    "@media (max-width:780px)": {
      width: "250px",
      height: "170px",
    },
  },
  stepImage: {
    width: "100%",
    height: "100%",
  },
  stepButtons: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "8%",
    paddingBottom: "0px !important",
    "@media (max-width:780px)": {
      justifyContent: "center",
      marginRight: "0%",
      marginTop: "10px",
    },
  },
  stepImageDefault: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "3%",
    paddingBottom: "0px !important",
    "@media (max-width:780px)": {
      justifyContent: "center",
      marginRight: "0%",
    },
  }
});
