import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { addNewData } from "../../redux/slices/dataSlice";
import { deleteData } from "../../redux/slices/dataSlice";
import { deleteKeywords } from "../../redux/slices/keywordsSlice";
import { deleteImagetexts } from "../../redux/slices/imagetextSlice";
import { handleCleanStorage } from "./utils";

const StartButton = ({ agreement }) => {
  const dispatch = useDispatch();
  const { t, ready } = useTranslation();

  const stepData = useSelector((state) => state?.userReducer);

  const handleInsertProject = () => {
    const body = {
      action: "insert_project",
      data: {
        amember_user_id: 12346,
        username: stepData?.username,
        company_name: "",
      },
    };
    dispatch(addNewData(body));
  };

  if (ready) {
    return (
      <Box display={"flex"} justifyContent={"center"}>
        <Link
          to={agreement ? "/stepOne" : "/"}
          style={{ textDecoration: "none", color: "#fff" }}
        >
          <Button
            variant="contained"
            disabled={!agreement}
            size="large"
            id="skip"
            onClick={() => {
              dispatch(deleteData());
              dispatch(deleteKeywords());
              dispatch(deleteImagetexts());
              handleCleanStorage();
              window.localStorage.setItem("keywords", "");
              window.localStorage.setItem("mode", null);
              window.localStorage.setItem("acceptedFiles", "");
              setTimeout(() => {
                handleInsertProject();
              }, 1000);
            }}
          >
            {t("Get Started")}
          </Button>
        </Link>
      </Box>
    );
  }
  return <span></span>;
};

export default StartButton;
