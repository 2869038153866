import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import bg from "../../assets/images/header.png";
import { useTranslation } from "react-i18next";
import { Grid, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { instructionSteps } from "./instructionSteps";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import { addNewData } from "../../redux/slices/dataSlice";
import { deleteData } from "../../redux/slices/dataSlice";
import { deleteKeywords } from "../../redux/slices/keywordsSlice";
import { deleteImagetexts } from "../../redux/slices/imagetextSlice";

const useStyles = makeStyles({
  userData: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "50px",
    paddingTop: "20px",
    "@media (max-width:780px)": {
      display: "block",
      paddingLeft: "20px",
    },
  },
  instruction: {
    backgroundColor: "#fff",
    borderRadius: "20px",
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    "@media (min-width:781px)": {
      marginLeft: "200px",
      marginRight: "200px",
    },
  },
  closeIcon: {
    float: "right",
    marginTop: "15px",
    marginRight: "15px",
  },
  title: {
    fontWeight: 600,
    fontSize: "20px",
  },
  value: {
    paddingTop: "5px",
    paddingLeft: "10px",
    fontSize: "16px",
    wordWrap: "break-word",
    "@media (max-width:780px)": {
      maxWidth: "250px",
    },
  },
  button: {
    width: "200px",
  },
  stepNumber: {
    fontWeight: 600,
  },
  stepText: {
    paddingTop: "10px",
  },
});

const UserPage = ({ setStepForm, setIsLoggedIn, setStepVideo }) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const [instruction, setInstruction] = useState(false);
  const [data, setData] = useState(null);
  const [videoData, setVideoData] = useState(null);

  const userData = useSelector(
    (state) => state.projectReducer?.projects?.[0]?.result?.[0]
  );

  const stepData = useSelector((state) => state?.userReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    setStepForm(false);
  }, [setStepForm]);

  useEffect(() => {
    setIsLoggedIn(true);
  }, [setIsLoggedIn]);

  useEffect(() => {
    setStepVideo(window.localStorage.getItem("video"));
  }, [setStepVideo]);

  useEffect(() => {
    window.localStorage.getItem("username");
  }, []);

  useEffect(() => {
    if (userData) {
      window.localStorage.setItem("userData", JSON.stringify(userData));
    }
  }, [userData, data]);

  useEffect(() => {
    setData(window.localStorage.getItem("userData"));
  }, [setData, data]);

  useEffect(() => {
    setVideoData(JSON.parse(window.localStorage.getItem("doVideo")));
  }, [setVideoData]);

  let companyName = videoData?.project?.company_name;
  let date = videoData?.project?.added;
  let videoUrl = videoData?.video?.url;

  const handleInsertProject = () => {
    const body = {
      action: "insert_project",
      data: {
        amember_user_id: 12346,
        username: stepData?.username,
        company_name: "",
      },
    };
    dispatch(addNewData(body));
  };

  const dataArray = [
    {
      title: "Date",
      value: date,
    },
    {
      title: "Name",
      value: window.localStorage.getItem("username"),
    },
    {
      title: "Company",
      value: companyName,
    },
    {
      title: "Video URL",
      value: videoUrl,
    },
    {
      title: "Language",
      value: "en-US",
    },
  ];

  if (ready) {
    return (
      <Box>
        <img
          src={bg}
          alt="header"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
        {dataArray.map((el, index) => (
          <Box key={index} className={classes.userData}>
            <Grid className={classes.title}>{t(el.title)}:</Grid>
            <Grid className={classes.value}>
              {el.title === "Video URL" ? (
                <Link to="">{el.value}</Link>
              ) : (
                <Typography>{el.value}</Typography>
              )}
            </Grid>
          </Box>
        ))}

        <Box paddingTop={"20px"}>
          {!instruction && (
            <Box p={1} display={"flex"} justifyContent={"center"}>
              <Button
                variant="contained"
                size="large"
                className={classes.button}
                onClick={() => {
                  setInstruction(true);
                  setTimeout(() => {
                    window.scrollTo(0, document.body.scrollHeight);
                  }, 100);
                }}
              >
                {t("Instruction")}
              </Button>
            </Box>
          )}

          <Box
            className={classes.instruction}
            marginTop={instruction && "20px"}
            border={instruction ? "1px solid #D8571C" : "none"}
          >
            {instruction && (
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => {
                  setInstruction(false);
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                  }, 100);
                }}
              />
            )}
            {instruction &&
              instructionSteps.map((el, index) => (
                <motion.div
                  key={index}
                  initial={{ width: 0 }}
                  animate={{
                    width: "100%",
                    transition: {
                      property: "max-height",
                      duration: 0.2,
                      transitionTimingFunction: "easeIn",
                    },
                  }}
                >
                  <Box p={3}>
                    <Grid className={classes.stepNumber}>
                      {t(el.stepNumber)}
                    </Grid>
                    <Grid className={classes.stepText}>{t(el.stepText)}</Grid>
                  </Box>
                </motion.div>
              ))}
          </Box>
          <Box
            p={1}
            display={"flex"}
            justifyContent={"center"}
            marginTop={instruction && "20px"}
          >
            <Link
              to="/stepOne"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <Button
                variant="contained"
                size="large"
                className={classes.button}
                onClick={() => {
                  dispatch(deleteData());
                  dispatch(deleteKeywords());
                  dispatch(deleteImagetexts());
                  window.localStorage.setItem("keywords", "");
                  window.localStorage.setItem("mode", null);
                  window.localStorage.setItem("acceptedFiles", "");
                  setTimeout(() => {
                    handleInsertProject();
                  }, 1000);
                }}
              >
                {t("Add New Report")}
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    );
  }
  return <span></span>;
};
export default UserPage;
