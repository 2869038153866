export const months = [
    {
      value: "January",
      label: "January",
      labelRus: "Январе", 
      labelEsp: "Enero",
      labelUkr: "Січні",
    },
    {
      value: "February",
      label: "February",
      labelRus: "Феврале",
      labelEsp: "Febrero",
      labelUkr: "Лютому",
    },
    {
      value: "March",
      label: "March",
      labelRus: "Марте",
      labelEsp: "Marzo",
      labelUkr: "Березні",
    },
    {
      value: "April",
      label: "April",
      labelRus: "Апреле",
      labelEsp: "Abril",
      labelUkr: "Квітні",
    },
    {
      value: "May",
      label: "May",
      labelRus: "Мае",
      labelEsp: "Mayo",
      labelUkr: "Травні",
    },
    {
      value: "June",
      label: "June",
      labelRus: "Июне",
      labelEsp: "Junio",
      labelUkr: "Червні",
    },
    {
      value: "July",
      label: "July",
      labelRus: "Июле",
      labelEsp: "Julio",
      labelUkr: "Липні",
    },
    {
      value: "August",
      label: "August",
      labelRus: "Августе",
      labelEsp: "Agosto",
      labelUkr: "Серпні",
    },
    {
      value: "September",
      label: "September",
      labelRus: "Сентябре",
      labelEsp: "Septiembre",
      labelUkr: "Вересні",
    },
    {
      value: "October",
      label: "October",
      labelRus: "Октябре",
      labelEsp: "Octubre",
      labelUkr: "Жовтні",
    },
    {
      value: "November",
      label: "November",
      labelRus: "Ноябре",
      labelEsp: "Noviembre",
      labelUkr: "Листопаді",
    },
    {
      value: "December",
      label: "December",
      labelRus: "Декабре",
      labelEsp: "Diciembre",
      labelUkr: "Грудні",
    },
];
  