import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Grid, Typography, Radio } from "@mui/material";
import { Box } from "@mui/system";
import bg from "../../assets/images/header.png";
import { textData } from "./data";
import { useStyles } from "./styles";
import Dropzone from "react-dropzone";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { uploadNewContent } from "../../redux/slices/contentUploadSlice";
import { Formik, Form } from "formik";
import Loader from "../../Components/Loader/Loader";
import Alert from "@mui/material/Alert";
import { getBusinessCard } from "../../redux/slices/businessCardSlice";

const StepOne = ({
  handleNext,
  setStepForm,
  fullPackage,
  setFullPackage,
  files,
  fileNames,
  setFiles,
  setFileNames,
  isLoggedIn,
}) => {
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState(null);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setStepForm(true);
  }, [setStepForm]);

  const { t, ready } = useTranslation();

  const dispatch = useDispatch();

  const acceptedFileNames =
    window.localStorage.getItem("acceptedFiles") === ""
      ? ""
      : JSON.parse(window.localStorage.getItem("acceptedFiles"));

  useEffect(() => {
    JSON.parse(window.localStorage.getItem("project_id"));
  }, []);

  useEffect(() => {
    setSelectedValue(window.localStorage.getItem("mode"));
  }, [setSelectedValue]);

  useEffect(() => {
    if (selectedValue === "a" || selectedValue === "b") {
      setChecked(true);
    }
  }, [setChecked, selectedValue]);

  const onSaveBusinessCard = (values) => {
    const data = new FormData();
    data.append("action", values.action);
    data.append("project_id", values.project_id);
    files.forEach((file, index) => data.append(`file[${index}]`, file));

    dispatch(uploadNewContent(data));
  };

  const onGetBusinessCard = () => {
    const body = {
      action: "do_business_card",
      project_id: JSON.parse(window.localStorage.getItem("project_id")),
    };
    dispatch(getBusinessCard(body));
  };

  const isLoading = useSelector(
    (state) => state.contentUploadReducer?.isLoading
  );

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  if (ready) {
    return (
      <Box>
        <img
          src={bg}
          alt="header"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
        {!isLoggedIn && (
          <Alert severity="warning"> {t("You are in the demo mode")}</Alert>
        )}
        <Formik
          initialValues={{ action: "upload_media_files", project_id: "" }}
          onSubmit={(values) => {
            onSaveBusinessCard(values);
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Form>
              <motion.div
                initial={{ width: 0 }}
                animate={{
                  width: "100%",
                  transition: {
                    transitionProperty: "max-height",
                    transitionDuration: "0.5s",
                    transitionTimingFunction: "ease-in",
                    transitionDelay: "0s",
                  },
                }}
              >
                <Box p={4}>
                  <hr />
                  <Typography className={classes.step} variant="h5">
                    {t("Step One")}
                  </Typography>
                  <hr />
                  {textData.map((el, index) => (
                    <Grid paddingTop={"10px"} key={index}>
                      {t(el)}
                    </Grid>
                  ))}
                  <Box className={classes.videoSelect}>
                    <Box display={"flex"}>
                      <Radio
                        checked={selectedValue === "a"}
                        onChange={handleChange}
                        onClick={() => {
                          window.localStorage.setItem("mode", "a");
                          setFullPackage(true);
                          setChecked(true);
                        }}
                        value="a"
                      />
                      <Grid className={classes.videoStyle}>
                        {t("Answer 16 questions")}
                      </Grid>
                    </Box>
                    <Box display={"flex"}>
                      <Radio
                        checked={selectedValue === "b"}
                        onChange={handleChange}
                        onClick={() => {
                          window.localStorage.setItem("mode", "b");
                          setFullPackage(false);
                          setChecked(true);
                        }}
                        value="b"
                      />
                      <Grid className={classes.videoStyle}>
                        {t("Insert freefrom text")}
                      </Grid>
                    </Box>
                  </Box>

                  <Box marginTop={"30px"}>
                    <Grid display={"flex"} justifyContent={"center"}>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          setFiles(acceptedFiles);
                          setFileNames(acceptedFiles.map((file) => file.name));
                          handleSubmit(acceptedFiles);
                          window.localStorage.setItem(
                            "acceptedFiles",
                            JSON.stringify(acceptedFiles)
                          );
                          setFieldValue(
                            "project_id",
                            JSON.parse(
                              window.localStorage.getItem("project_id")
                            )
                          );
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <Box {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <Grid>
                              {t("Drag'n'drop files, or click to select files")}
                            </Grid>
                          </Box>
                        )}
                      </Dropzone>
                    </Grid>
                    <Box>
                      <strong>{t("Files")}</strong>
                      <ul>
                        {acceptedFileNames &&
                          acceptedFileNames.map((fileName) => (
                            <li key={fileName.path}>{fileName.path}</li>
                          ))}
                      </ul>
                    </Box>
                  </Box>
                  {isLoading && <Loader />}
                  <Box
                    p={3}
                    paddingBottom={"0px"}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Link
                      to={
                        checked &&
                        !isLoading &&
                        (fullPackage ? "/stepTwoFullPackage" : "/stepTwo")
                      }
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        disabled={!checked || isLoading}
                        className={classes.button}
                        onClick={() => {
                          // if (!fileNames || fileNames.length === 0) {
                          //   onGetBusinessCard();
                          // }
                          handleNext();
                          window.scrollTo(0, 0);
                          // setTimeout(() => {
                          //   window.location.reload();
                          // }, 1500);
                        }}
                      >
                        {t("Next Step")}
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </motion.div>
            </Form>
          )}
        </Formik>
      </Box>
    );
  }
  return <span></span>;
};
export default StepOne;
