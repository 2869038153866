import React, { useEffect } from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import bg from "../../assets/images/header.png";
import { useTranslation } from "react-i18next";
import { data } from "./data";

const useStyles = makeStyles({
  aboutMain: {
    padding: "50px",
  },
  aboutParagraph: {
    paddingTop: "20px",
  },
});

const About = ({ setStepForm }) => {
  const classes = useStyles();

  useEffect(() => {
    setStepForm(false);
  }, [setStepForm]);

  const { t, ready } = useTranslation();

  if (ready) {
    return (
      <Box>
        <img
          src={bg}
          alt="header"
          style={{
            width: "100%",
            height: "auto",
          }}
        />

        <Box className={classes.aboutMain}>
          <Typography variant="h4">{t("About this project.")}</Typography>

          {data.map((item, index) => (
            <Grid className={classes.aboutParagraph} key={index}>
              {item.text === "Mark Aretskin" ? <hr /> : null}
              {t(item.text)}
            </Grid>
          ))}

          <Typography>
            <Link color="inherit" href="http://www.perfectinvention.com">
              perfectnvention.com
            </Link>
          </Typography>
        </Box>
      </Box>
    );
  }
  return <span></span>;
};

export default About;
