export const rates = [
  {
    title: "The Startup Video Press Release Builder, one-month $60 access",
    amount: "60.00",
  },
  { 
    title: "The Startup Video Press Release Builder, 12 Payments of $40 per month",
    amount: "40.00",
  },
  {
    title: "The Startup Video Press Release Builder One Year Subscription 1-payment $360 (Save 25%)",
    amount: "360.00",
  },
];
