import React, { useEffect, useState } from "react";
import { Typography, Link } from "@mui/material";
import { Box } from "@mui/system";
import bg from "../../assets/images/header.png";
import { makeStyles } from "@mui/styles";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { data } from "./data";
import { useSelector } from "react-redux";
import Loader from "../../Components/Loader/Loader";

const useStyles = makeStyles({
  step: {
    textTransform: "uppercase",
  },
  stepLink: {
    "@media (max-width:780px)": {
      fontSize: "10px",
    },
  },
  titleName: {
    margin: "20px 0 20px 40px !important",
    "@media (max-width:780px)": {
      margin: "20px 0 !important",
    },
  },
  titleText: {
    marginBottom: "10px !important",
    marginLeft: "40px !important",
    "@media (max-width:780px)": {
      margin: "0px !important",
    },
  },
});

const StepFive = ({ setStepForm }) => {
  const classes = useStyles();

  const [videoData, setVideoData] = useState(null);

  const { t, ready } = useTranslation();
  const isLoading = useSelector(
    (state) => state.sendFinalEmailReducer?.isLoading
  );

  useEffect(() => {
    setStepForm(false);
  }, [setStepForm]);

  useEffect(() => {
    setVideoData(JSON.parse(window.localStorage.getItem("doVideo")));
  }, [setVideoData]);

  let pitch = videoData?.video?.pitch_url;
  let audioUrl = videoData?.video?.audio_url;
  let videoUrl = videoData?.video?.url;

  const user = useSelector((state) => state.userReducer.username);

  if (ready) {
    return (
      <Box>
        <img
          src={bg}
          alt="header"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
        <motion.div
          initial={{ width: 0 }}
          animate={{
            width: "100%",
            transition: {
              transitionProperty: "max-height",
              transitionDuration: "1s",
              transitionTimingFunction: "ease-in",
              transitionDelay: "0s",
            },
          }}
        >
          {user ? (
            <Box p={4}>
              <hr />
              <Typography className={classes.step} variant="h5">
                {t("Congratulations!")}
              </Typography>
              <hr />

              <Typography
                className={classes.titleName}
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                }}
              >
                {user ? user + "!" : null}
              </Typography>

              <Typography variant="h6" className={classes.titleText}>
                {t("Please download your press kit files.")}
              </Typography>

              {isLoading === true ? (
                <Loader />
              ) : (
                <Box sx={{ marginTop: "40px" }}>
                  {data.map((item, index) => (
                    <Box
                      key={index}
                      sx={{ marginTop: index === 4 ? "20px" : "0px" }}
                    >
                      <Typography
                        style={{
                          fontWeight:
                            (index === 0 ||
                              index === 1 ||
                              index === 2 ||
                              index === 3) &&
                            700,
                        }}
                      >
                        {t(item.text)}
                      </Typography>
                      {index === 0 && (
                        <Link
                          color="inherit"
                          href={videoUrl}
                          target="_blank"
                          className={classes.stepLink}
                        >
                          {videoUrl}
                        </Link>
                      )}

                      {index === 1 && (
                        <Link
                          color="inherit"
                          href={audioUrl}
                          target="_blank"
                          className={classes.stepLink}
                        >
                          {audioUrl}
                        </Link>
                      )}

                      {index === 2 && (
                        <Link
                          color="inherit"
                          href={pitch}
                          target="_blank"
                          className={classes.stepLink}
                        >
                          {pitch}
                        </Link>
                      )}
                      {index === 3 && (
                        <Box>{window.localStorage.getItem("keywords")}</Box>
                      )}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          ) : (
            <Box
              style={{ height: "67vh", padding: "0 40px" }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography style={{ fontSize: "20px", fontWeight: 700 }}>
                {t(
                  "You are in the demo mode. Please register to save your files."
                )}
              </Typography>
            </Box>
          )}
        </motion.div>
      </Box>
    );
  }
  return <span></span>;
};

export default StepFive;
