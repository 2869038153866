import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Toolbar, Button, Grid } from "@mui/material";
import { LogoIcon2 } from "../../assets/icons";
import { makeStyles } from "@mui/styles";
import NavMenu from "../NavMenu/NavMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { loginUser, logoutUser } from "../../redux/slices/userSlice";

const useStyles = makeStyles({
  box: {
    backgroundColor: "#D8571C",
    width: "100%",
    maxHeight: 64,
    "@media (max-width:780px)": {
      height: 54,
    },
  },
  logo: {
    cursor: "pointer",
    "@media (max-width:780px)": {
      with: "30px",
      height: "30px",
    },
  },
  Buttons: {
    display: "flex",
    marginLeft: "80px",
  },
  logInButton: {
    color: "#262627 !important",
    border: "1px solid #262627 !important",
  },
  signUpButton: {
    color: "#FFF !important",
    background: "#262627 !important",
    marginLeft: "15px !important",
  },
  burgerMenu: {
    display: "none",
    "@media (max-width:780px)": {
      display: "block",
    },
  },
  navDesktop: {
    display: "block",
    "@media (max-width:780px)": {
      display: "none",
    },
  },
});

const NavBar = ({
  openNav,
  setOpenNav,
  isLoggedIn,
  setIsLoggedIn,
  setLanguage,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, ready } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(loginUser());
  }, [dispatch]);

  useEffect(() => {
    setIsLoggedIn(JSON.parse(window.localStorage.getItem("isLoggedIn")));
  }, [setIsLoggedIn]);

  useEffect(() => {
    window.localStorage.getItem("username");
  }, []);

  const user = useSelector((state) => state?.userReducer);

  const isLoggedOutFunction = () => {
    localStorage.setItem("isLoggedIn", false);
    setIsLoggedIn({ isLoggedIn: localStorage.getItem("isLoggedIn") });
  };

  if (ready) {
    return (
      <Box sx={{ flexGrow: 1 }} className={classes.box}>
        <Box className={classes.navDesktop}>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <LogoIcon2 className={classes.logo} />
            </Box>

            <Box sx={{ flexGrow: 2 }}>
              <NavMenu open={openNav} setLanguage={setLanguage} />
            </Box>

            {isLoggedIn !== false &&
            JSON.parse(window.localStorage.getItem("isLoggedIn")) !== null &&
            JSON.parse(window.localStorage.getItem("isLoggedIn")) !== "" ? (
              <>
                {location.pathname !== "/PayPal" && (
                  <Box
                    className={classes.Buttons}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Link
                      to="/userPage"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <Grid>{user.username}</Grid>
                    </Link>

                    <Button
                      className={classes.signUpButton}
                      onClick={() => {
                        isLoggedOutFunction();
                        setIsLoggedIn(false);
                        dispatch(logoutUser());
                        navigate("/");
                      }}
                    >
                      {t("Log out")}
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <>
                {location.pathname !== "/PayPal" && (
                  <Box className={classes.Buttons}>
                    <Link to="/login" style={{ textDecoration: "none" }}>
                      <Button className={classes.logInButton}>
                        {t("Log in")}
                      </Button>
                    </Link>

                    {/* <Link to="/signUp" style={{ textDecoration: "none" }}>
                      <Button className={classes.signUpButton}>
                        {t("Sign up")}
                      </Button>
                    </Link> */}
                  </Box>
                )}
              </>
            )}
          </Toolbar>
        </Box>

        {location.pathname !== "/PayPal" && (
          <Box className={classes.burgerMenu}>
            <Toolbar>
              <Box sx={{ flexGrow: 1 }}>
                <LogoIcon2 className={classes.logo} />
              </Box>
              <MenuIcon
                onClick={() => {
                  setOpenNav(true);
                }}
              />
            </Toolbar>
          </Box>
        )}
      </Box>
    );
  }
  return <span></span>;
};
export default NavBar;
