import React from "react";
import { Box } from "@mui/material";
import NavItem from "../NavItem/NavItem";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import LangMenu from "../LangMenu/LangMenu";

const useStyles = makeStyles({
  navLink: {
    textDecoration: "none",
    color: "#000",
  },
});

const NavMenu = ({ open, setLanguage }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, ready } = useTranslation();

  if (ready) {
    return (
      <>
        {location.pathname !== "/PayPal" && (
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Box
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }}
            >
              <NavItem open={open} title={t("Home")} />
            </Box>

            <Link to="/about" className={classes.navLink}>
              <NavItem open={open} title={t("About Project")} />
            </Link>
            <LangMenu open={true} setLanguage={setLanguage} />
          </Box>
        )}
      </>
    );
  }
  return <span></span>;
};
export default NavMenu;
