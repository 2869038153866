import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  Radio,
  TextField,
  Stack,
  Autocomplete,
} from "@mui/material";
import { Box } from "@mui/system";
import bg from "../../assets/images/header.png";
import demo from "../../assets/images/demo.jpg";
import { makeStyles } from "@mui/styles";
import { industryTypes } from "./industryTypes";
import { months } from "./months";
import { years } from "./years";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { addNewData } from "../../redux/slices/dataSlice";
import { addNewKeywords } from "../../redux/slices/keywordsSlice";
import { addImagetexts } from "../../redux/slices/imagetextSlice";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Alert from '@mui/material/Alert';
import { getBusinessCard } from "../../redux/slices/businessCardSlice";
/* eslint-disable no-useless-escape*/

const useStyles = makeStyles({
  step: {
    textTransform: "uppercase",
  },
  stepAvatar: {
    width: "400px",
    height: "250px",
    marginTop: "30px",
    border: "5px solid #96b0c1",
    borderRadius: "10px",
    "@media (max-width:780px)": {
      width: "250px",
      height: "150px",
    },
  },
  checkboxes: {
    marginTop: "30px",
  },
  voiceSelect: {
    marginTop: "30px",
  },
  voice: {
    marginTop: "13px",
  },
  proper: {
    marginTop: "13px",
  },
  button: {
    "@media (max-width:780px)": {
      fontSize: "10px !important",
    },
  },
  imageBlock: {
    display: "flex",
    justifyContent: "space-evenly",
    "@media (max-width:970px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  stepImageBox: {
    width: "400px",
    height: "250px",
    marginTop: "30px",
    border: "5px solid #96b0c1",
    borderRadius: "10px",
    "@media (max-width:1350px)": {
      width: "350px",
      height: "250px",
    },
    "@media (max-width:1220px)": {
      width: "300px",
      height: "250px",
    },
    "@media (max-width:1154px)": {
      width: "280px",
      height: "235px",
    },
    "@media (max-width:780px)": {
      width: "250px",
      height: "170px",
    },
  },
  stepImage: {
    width: "100%",
    height: "100%",
  },
});

const maskPhone = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1-$2")
    .replace(/(\d{3})(\d)/, "$1-$2")
    .replace(/(-\d{8})(\d+?)$/, "$1");
};

const StepTwo = ({
  handleNext,
  handleBack,
  setFullPackage,
  stepImage,
  setStepImage,
  isVideo,
  setIsVideo,
  language,
  fileNames,
  isLoggedIn,
}) => {
  const classes = useStyles();
  const [save, setSave] = useState(false);
  const [saveButton, setSaveButton] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedGenderValue, setSelectedGenderValue] = useState(null);
  const [selectedProviderValue, setSelectedProviderValue] = useState(null);
  const [selectedBusinessValue, setSelectedBusinessValue] = useState(null);
  const [tel, setTel] = useState("");
  const [website, setWebsite] = useState("");
  const [industryValue, setIndustryValue] = useState("");
  const [voiceId, setVoiceId] = useState(null);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [businessCard, setBusinessCard] = useState("");

  const localData = JSON.parse(localStorage.getItem("content"));
  const projectImage = localStorage.getItem("image");

  const dispatch = useDispatch();

  useEffect(() => {
    JSON.parse(window.localStorage.getItem("project_id"));
  }, []);

  const stepVideoId = useSelector(
    (state) => state?.contentUploadReducer?.content?.[0]?.id
  );

  const stepKeywords = useSelector(
    (state) => state?.keywordsReducer?.keywords?.[0]?.result
  );

  const onSaveProject = (values) => {
    const body = {
      action: "update_project",
      id: JSON.parse(window.localStorage.getItem("project_id")),

      data: {
        username: values.name,
        amember_user_id: 12345,
        video_id: stepVideoId,
        salutation: values.salutation,
        name: values.name,
        job_position: values.title,
        company_name: values.company_name,
        industry: values.industry,
        buz_type: values.companyType,
        cust_type: values.activity,
        customer: values.customers,
        prod_serv_description: values.description,
        accomplishments: values.accomplishments,
        goal: values.goal,
        needs: values.yourNeeds,
        tel: tel,
        website: website,
        email: values.email,
        gender: values.gender,
        lang_report: !window.localStorage.getItem("language") ? language : window.localStorage.getItem("language"),
        generate_with_own_bc: fileNames.length !== 0 ? 0 : 1,
        pitch: values.pitch,
        keywords: "Ключевые слова",
        term_and_conditions_date: "2022-02-25 13:48:49",
        vimeo_url: "vimeo_url",
        voice_id: voiceId,
        dtformat: `${month} ${year}`,
      },
    };
    dispatch(addNewData(body));
  };

  const onGetProject = (values) => {
    const body = {
      action: "get_project",
      id: JSON.parse(window.localStorage.getItem("project_id")),

      data: {
        username: values.name,
        amember_user_id: 12345,
        video_id: stepVideoId,
        salutation: values.salutation,
        name: values.name,
        job_position: values.title,
        company_name: values.company_name,
        industry: values.industry,
        buz_type: values.companyType,
        cust_type: values.activity,
        customer: values.customers,
        prod_serv_description: values.description,
        accomplishments: values.accomplishments,
        goal: values.goal,
        needs: values.yourNeeds,
        tel: tel,
        website: website,
        email: values.email,
        gender: values.gender,
        lang_report: !window.localStorage.getItem("language") ? language : window.localStorage.getItem("language"),
        pitch: values.pitch,
        keywords: "Ключевые слова",
        generate_with_own_bc: fileNames.length !== 0 ? false : true,
        term_and_conditions_date: "2022-02-25 13:48:49",
        vimeo_url: "vimeo_url",
        voice_id: voiceId,
        dtformat: `${month} ${year}`,
      },
    };
    dispatch(addNewData(body));
  };

  const onGetKeywords = () => {
    const body = {
      action: "get_keywords",
      id: JSON.parse(window.localStorage.getItem("project_id")),
    };

    dispatch(addNewKeywords(body));
  };

  const onGetImageTexts = () => {
    const body = {
      action: "get_imagetexts",
      project_id: JSON.parse(window.localStorage.getItem("project_id")),
    };
    dispatch(addImagetexts(body));
  };

  const onGetBusinessCard = () => {
    const body = {
      action: "do_business_card",
      project_id: JSON.parse(window.localStorage.getItem("project_id")),
    };
    dispatch(getBusinessCard(body));
  };
  
  const handleCheckboxChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleCheckboxProviderChange = (event) => {
    setSelectedProviderValue(event.target.value);
  };

  const handleCheckboxBusinessChange = (event) => {
    setSelectedBusinessValue(event.target.value);
  };

  const handleChangeGender = (event) => {
    setSelectedGenderValue(event.target.value);
  };

  const fullPackageFunction = () => {
    localStorage.setItem("fullPackage", true);
    setFullPackage({ fullPackage: localStorage.getItem("fullPackage") });
  };

  const { i18n, t, ready } = useTranslation();

  useEffect(() => {
    window.localStorage.getItem("name");
    window.localStorage.getItem("title");
    window.localStorage.getItem("company_name");
    window.localStorage.getItem("industry");
    window.localStorage.getItem("customers");
    window.localStorage.getItem("description");
    window.localStorage.getItem("accomplishments");
    window.localStorage.getItem("goal");
    window.localStorage.getItem("yourNeeds");
    window.localStorage.getItem("tel");
    window.localStorage.getItem("website");
    window.localStorage.getItem("email");
  }, []);

  
  useEffect(() => {
    try {
     setTimeout(() => {
      setBusinessCard(JSON.parse(window.localStorage.getItem("do_business_card")));
    }, 1000)
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setSelectedValue(window.localStorage.getItem("salutation"));
  }, [setSelectedValue]);

  useEffect(() => {
    setSelectedProviderValue(window.localStorage.getItem("activity"));
  }, [setSelectedProviderValue]);

  useEffect(() => {
    setSelectedBusinessValue(window.localStorage.getItem("companyType"));
  }, [setSelectedBusinessValue]);

  useEffect(() => {
    setIndustryValue(window.localStorage.getItem("industry"));
  }, [setIndustryValue]);

  useEffect(() => {
    setTel(window.localStorage.getItem("tel"));
  }, [setTel]);

  useEffect(() => {
    setWebsite(window.localStorage.getItem("website"));
  }, [setWebsite]);

  useEffect(() => {
    setStepImage(window.localStorage.getItem("image"));
  }, [setStepImage]);

  useEffect(() => {
    setIsVideo(window.localStorage.getItem("is_video"));
  }, [setIsVideo]);

  const emailRegExp = "^[a-zA-Z0-9_'+*/^&=?~{}\-](\.?[a-zA-Z0-9_'+*/^&=?~{}\-])*\@((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\:\d{1,3})?)|(((([a-zA-Z0-9][a-zA-Z0-9\-]+[a-zA-Z0-9])|([a-zA-Z0-9]{1,2}))[\.]{1})+([a-zA-Z]{2,6})))$"

  const formValidation = Yup.object({
    name: Yup.string().required(() => i18n.t("Required")).nullable(),
    title: Yup.string().required(() => i18n.t("Required")).nullable(),
    company_name: Yup.string().required(() => i18n.t("Required")).nullable(),
    customers: Yup.string().required(() => i18n.t("Required")).nullable(),
    description: Yup.string().required(() => i18n.t("Required")).nullable(),
    accomplishments: Yup.string().required(() => i18n.t("Required")).nullable(),
    goal: Yup.string().required(() => i18n.t("Required")).nullable(),
    yourNeeds: Yup.string().required(() => i18n.t("Required")).nullable(),
    email: Yup.string("Enter your email")
      .email(() => i18n.t("email must be a valid email"))
      .required(() => i18n.t("Required")).nullable(),
    industry: Yup.string().required(() => i18n.t("Required")).nullable(),
    activity: Yup.string().required(() => i18n.t("Required")).nullable(),
    companyType: Yup.string().required(() => i18n.t("Required")).nullable(),
    salutation: Yup.string().required(() => i18n.t("Required")).nullable(),
  });
  if (ready) {
    return (
      <Box>
        <img
          src={bg}
          alt="header"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
        {!isLoggedIn &&
          <Alert severity="warning"> {t("You are in the demo mode")}</Alert>
        }
        <Formik
          initialValues={{
            amember_user_id: 12345,
            salutation: window.localStorage.getItem("salutation") || '',
            name: window.localStorage.getItem("name") || '',
            title: window.localStorage.getItem("title") || '',
            company_name: window.localStorage.getItem("company_name") || '',
            industry: window.localStorage.getItem("industry") || '',
            activity: window.localStorage.getItem("activity") || '',
            companyType: window.localStorage.getItem("companyType") || '',
            customers: window.localStorage.getItem("customers") || '',
            description: window.localStorage.getItem("description") || '',
            accomplishments: window.localStorage.getItem("accomplishments") || '',
            goal: window.localStorage.getItem("goal") || '',
            yourNeeds: window.localStorage.getItem("yourNeeds") || '',
            email: window.localStorage.getItem("email") || '',
            gender: window.localStorage.getItem("gender") || '',
          }}
          onSubmit={(values) => {
            // onGetBusinessCard();
            onSaveProject(values);
            onGetProject(values);
            setTimeout(() => {
              onGetImageTexts(values);
            }, 1500);
            setTimeout(() => {
              onGetKeywords(values);
            }, 1000);
            // setTimeout(() => {
            //   onGetBusinessCard();
            // }, 500);
          }}
          validationSchema={formValidation}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            touched,
            errors,
            handleChange,
          }) => (
            <Form>
              <motion.div
                initial={{ width: 0 }}
                animate={{
                  width: "100%",
                  transition: {
                    transitionProperty: "max-height",
                    transitionDuration: "0.5s",
                    transitionTimingFunction: "ease-in",
                    transitionDelay: "0s",
                  },
                }}
              >
                <Box p={4}>
                  <hr />
                  <Typography className={classes.step} variant="h5">
                    {t("Step Two")}
                  </Typography>
                  <hr />
                  <Grid paddingTop={"10px"}>
                    {t("Please answer to 16 questions about your business.")}
                  </Grid>
                  <Grid paddingTop={"10px"}>
                    {t(
                      "Carefully read explanations and examples for each question."
                    )}
                  </Grid>
                  <Grid paddingTop={"10px"}>
                    {t(
                      "Note that by FTC regulations, you are not allowed to directly solicit money using this software"
                    )}
                  </Grid>

                  {localData ? (
                    <Box
                      className={classes.imageBlock}
                      display={"flex"}
                      justifyContent={"space-evenly"}
                      flexWrap={"wrap"}
                    >
                      {localData.map((item, index) => {
                        return (
                          <Box key={index} className={classes.stepImageBox}>
                            {isVideo && item.is_video === 0 ? (
                              <img
                                src={
                                  !item.original_url ?
                                    demo :
                                    item.original_url
                                }
                                alt=""
                                className={classes.stepImage}
                              />
                            ) : (
                              <video
                                style={{ objectFit: "cover" }}
                                src={item.original_url}
                                className={classes.stepImage}
                              />
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  ) : (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      flexWrap={"wrap"}
                    >
                      <img
                        src={
                          isVideo && isVideo === "0" && projectImage
                            ? stepImage
                            : demo
                        }
                        alt="callback"
                        className={classes.stepAvatar}
                      />
                    </Box>
                  )}

                  <Box className={classes.voiceSelect}>
                    <Grid>
                      {"*"} {t("Please select a proper voice for your video.")}
                    </Grid>
                    <Box display={"flex"}>
                      <Radio
                        name="gender"
                        required={true}
                        checked={selectedGenderValue === "male"}
                        onChange={handleChangeGender}
                        onClick={(e) => {
                          window.localStorage.setItem("gender", e.target.value);
                          setFieldValue("gender", e.target.value);
                          if (window.localStorage.getItem("language")) {
                            if (window.localStorage.getItem("language") === "en-US") {
                              setVoiceId("Matthew")
                            } else if (window.localStorage.getItem("language") === "ru") {
                              setVoiceId("Maxim")
                            } else if (window.localStorage.getItem("language") === "es-ES") {
                              setVoiceId("Miguel")
                            } else {
                              setVoiceId("Volodymyr")
                            }
                          } else {
                            if (language === "en-US") {
                              setVoiceId("Matthew")
                            } else if (language === "ru") {
                              setVoiceId("Maxim")
                            } else if (language === "es-ES") {
                              setVoiceId("Miguel")
                            } else {
                              setVoiceId("Volodymyr")
                            }
                          }
                        }}
                        value="male"
                      />
                      <Grid className={classes.voice}>{t("Male")}</Grid>
                    </Box>
                    <Box display={"flex"}>
                      <Radio
                        name="gender"
                        required={true}
                        checked={selectedGenderValue === "female"}
                        onChange={handleChangeGender}
                        onClick={(e) => {
                          window.localStorage.setItem("gender", e.target.value);
                          setFieldValue("gender", e.target.value);
                          if (window.localStorage.getItem("language")) {
                            if (window.localStorage.getItem("language") === "en-US") {
                              setVoiceId("Joanna")
                            } else if (window.localStorage.getItem("language") === "ru") {
                              setVoiceId("Tatyana")
                            } else if (window.localStorage.getItem("language") === "es-ES") {
                              setVoiceId("Lupe")
                            } else {
                              setVoiceId("Natalia")
                            }
                          } else {
                            if (language === "en-US") {
                              setVoiceId("Joanna")
                            } else if (language === "ru") {
                              setVoiceId("Tatyana")
                            } else if (language === "es-ES") {
                              setVoiceId("Lupe")
                            } else {
                              setVoiceId("Natalia")
                            }
                          }
                        }}
                        value="female"
                      />
                      <Grid className={classes.voice}>{t("Female")}</Grid>
                    </Box>
                  </Box>

                  <Box className={classes.checkboxes}>
                    <Grid>
                      {t(
                        "Please select a proper salutation. (Mr. Mrs. Ms. or Dr.)*"
                      )}
                    </Grid>
                    <Box display={"flex"}>
                      <Radio
                        name="salutation"
                        required={true}
                        checked={
                          !window.localStorage.getItem('language') ?
                            ((language === "en-US" && selectedValue === "Mr.") ||
                              (language === "ru" && selectedValue === "господина") ||
                              (language === "es-ES" && selectedValue === "Sr.") ||
                              (language === "ua" && selectedValue === "пана")
                            )
                            :
                            ((window.localStorage.getItem('language') === "en-US" && selectedValue === "Mr.") ||
                              (window.localStorage.getItem('language') === "ru" && selectedValue === "господина") ||
                              (window.localStorage.getItem('language') === "es-ES" && selectedValue === "Sr.") ||
                              (window.localStorage.getItem('language') === "ua" && selectedValue === "пана")
                            )
                        }
                        onChange={handleCheckboxChange}
                        onClick={(e) => {
                          window.localStorage.setItem(
                            "salutation",
                            e.target.value
                          );
                          setFieldValue("salutation", e.target.value);
                        }}
                        value={
                          !window.localStorage.getItem('language') ?
                            (language === "en-US" ? "Mr." : language === "ru" ? "господина"
                              : language === "es-ES" ? "Sr." : "пана") :
                            (window.localStorage.getItem('language') === "en-US" ? "Mr."
                              : window.localStorage.getItem('language') === "ru" ? "господина"
                                : window.localStorage.getItem('language') === "es-ES" ? "Sr." : "пана")
                        }
                      />
                      <Grid className={classes.proper}>{t("Mr.")}</Grid>
                    </Box>
                    <Box display={"flex"}>
                      <Radio
                        name="salutation"
                        required={true}
                        checked={
                          !window.localStorage.getItem('language') ?
                            ((language === "en-US" && selectedValue === "Mrs.") ||
                              (language === "ru" && selectedValue === "госпожу") ||
                              (language === "es-ES" && selectedValue === "Sra.") ||
                              (language === "ua" && selectedValue === "пані")
                            ) :
                            (
                              (window.localStorage.getItem('language') === "en-US" && selectedValue === "Mrs.") ||
                              (window.localStorage.getItem('language') === "ru" && selectedValue === "госпожу") ||
                              (window.localStorage.getItem('language') === "es-ES" && selectedValue === "Sra.") ||
                              (window.localStorage.getItem('language') === "ua" && selectedValue === "пані")
                            )
                        }
                        onChange={handleCheckboxChange}
                        onClick={(e) => {
                          window.localStorage.setItem(
                            "salutation",
                            e.target.value
                          );
                          setFieldValue("salutation", e.target.value);
                        }}
                        value={!window.localStorage.getItem('language') ?
                          (language === "en-US" ? "Mrs." : language === "ru" ? "госпожу"
                            : language === "es-ES" ? "Sra." : "пані") :
                          (window.localStorage.getItem('language') === "en-US" ?
                            "Mrs." : window.localStorage.getItem('language') === "ru" ? "госпожу"
                              : window.localStorage.getItem('language') === "es-ES" ? "Sra." : "пані")
                        }
                      />
                      <Grid className={classes.proper}>{t("Mrs.")}</Grid>
                    </Box>
                    <Box display={"flex"}>
                      <Radio
                        name="salutation"
                        required={true}
                        checked={
                          !window.localStorage.getItem('language') ?
                            (
                              (language === "en-US" && selectedValue === "Ms.") ||
                              (language === "ru" && selectedValue === "мисс") ||
                              (language === "es-ES" && selectedValue === "Signorita") ||
                              (language === "ua" && selectedValue === "міс")
                            ) :
                            (
                              (window.localStorage.getItem('language') === "en-US" && selectedValue === "Ms.") ||
                              (window.localStorage.getItem('language') === "ru" && selectedValue === "мисс") ||
                              (window.localStorage.getItem('language') === "es-ES" && selectedValue === "Signorita") ||
                              (window.localStorage.getItem('language') === "ua" && selectedValue === "міс")
                            )
                        }
                        onChange={handleCheckboxChange}
                        onClick={(e) => {
                          window.localStorage.setItem(
                            "salutation",
                            e.target.value
                          );
                          setFieldValue("salutation", e.target.value);
                        }}
                        value={
                          !window.localStorage.getItem('language') ?
                            (language === "en-US" ? "Ms." : language === "ru" ? "мисс"
                              : language === "es-ES" ? "Signorita" : "міс") :
                            (window.localStorage.getItem('language') === "en-US" ?
                              "Ms." : window.localStorage.getItem('language') === "ru" ? "мисс"
                                : window.localStorage.getItem('language') === "es-ES" ? "Signorita" : "міс")
                        }
                      />
                      <Grid className={classes.proper}>{t("Ms.")}</Grid>
                    </Box>
                    <Box display={"flex"}>
                      <Radio
                        name="salutation"
                        required={true}
                        checked={
                          !window.localStorage.getItem('language') ?
                            ((language === "en-US" && selectedValue === "Dr.") ||
                              (language === "ru" && selectedValue === "доктора") ||
                              (language === "es-ES" && selectedValue === "Dr.") ||
                              (language === "ua" && selectedValue === "доктора")
                            ) :
                            ((window.localStorage.getItem('language') === "en-US" && selectedValue === "Dr.") ||
                              (window.localStorage.getItem('language') === "ru" && selectedValue === "доктора") ||
                              (window.localStorage.getItem('language') === "es-ES" && selectedValue === "Dr.") ||
                              (window.localStorage.getItem('language') === "ua" && selectedValue === "доктора")
                            )
                        }
                        onChange={handleCheckboxChange}
                        onClick={(e) => {
                          window.localStorage.setItem(
                            "salutation",
                            e.target.value
                          );
                          setFieldValue("salutation", e.target.value);
                        }}
                        value={
                          !window.localStorage.getItem('language') ?
                            (language === "en-US" ? "Dr." : language === "ru" ? "доктора"
                              : language === "es-ES" ? "Dr." : "доктора") :
                            (window.localStorage.getItem('language') === "en-US" ? "Dr." :
                              window.localStorage.getItem('language') === "ru" ? "доктора" :
                                window.localStorage.getItem('language') === "es-ES" ? "Dr." : "доктора")
                        }
                      />
                      <Grid className={classes.proper}>{t("Dr.")}</Grid>
                    </Box>
                  </Box>
                  <Box marginTop={"15px"}>
                    <Grid>{t("Please enter your first and last name. *")}</Grid>
                    <TextField
                      id="text"
                      label={t("First name and last name")}
                      name="name"
                      autoComplete="nope"
                      value={values.name}
                      onChange={(event) => {
                        window.localStorage.setItem("name", event.target.value);
                        handleChange(values.name);
                        setFieldValue("name", event.target.value);
                      }}
                      style={{ marginTop: "15px" }}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Box>
                  <Box marginTop={"15px"}>
                    <Grid>
                      {t(
                        "Please enter your title. (President, CEO, Owner etc.) *"
                      )}
                    </Grid>
                    <TextField
                      id="text"
                      label={t("Title")}
                      name="title"
                      autoComplete="off"
                      value={values.title}
                      onChange={(event) => {
                        window.localStorage.setItem(
                          "title",
                          event.target.value
                        );
                        handleChange(values.title);
                        setFieldValue("title", event.target.value);
                      }}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      style={{ marginTop: "15px" }}
                    />
                  </Box>
                  <Box marginTop={"15px"}>
                    <Grid>{t("What is your company name? *")}</Grid>
                    <TextField
                      id="text"
                      label={t("Company Name")}
                      name="company_name"
                      autoComplete="off"
                      value={values.company_name}
                      onChange={(event) => {
                        window.localStorage.setItem(
                          "company_name",
                          event.target.value
                        );
                        handleChange(values.company_name);
                        setFieldValue("company_name", event.target.value);
                      }}
                      error={Boolean(
                        touched.company_name && errors.company_name
                      )}
                      helperText={touched.company_name && errors.company_name}
                      style={{ marginTop: "15px" }}
                    />
                  </Box>


                  <Box marginTop={"15px"}>
                    <Grid>
                      {t(
                        "When was your company founded? *"
                      )}
                    </Grid>
                    <Box display={"flex"}>
                      <Stack sx={{ maxWidth: 235 }}>
                        <Autocomplete
                          style={{ marginTop: "15px" }}
                          freeSolo
                          disableClearable
                          name="month"
                          options={months}
                          getOptionLabel={(option) => t(option.label)}
                          onChange={(event, value) => {
                            window.localStorage.setItem(
                              "month",
                              event.target.value
                            );
                            setMonth(!window.localStorage.getItem('language') ?
                              (language === "en-US" ? value.label : language === "ru" ? value.labelRus :
                                language === "es-ES" ?
                                  value.labelEsp
                                  : value.labelUkr
                              ) :
                              (window.localStorage.getItem('language') === "en-US" ? value.label :
                                window.localStorage.getItem('language') === "ru" ? value.labelRus :
                                  window.localStorage.getItem('language') === "es-ES" ?
                                    value.labelEsp
                                    : value.labelUkr
                              )
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Month")}
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              name="month"
                              value={month}
                              required={true}
                              style={{ width: "150px" }}
                              onChange={(event) => {
                                window.localStorage.setItem(
                                  "month",
                                  event.target.value
                                );
                                setMonth(event.target.value);
                              }}
                            />
                          )}
                        />
                      </Stack>

                      <Stack>
                        <Autocomplete
                          freeSolo
                          disableClearable
                          name="year"
                          options={years}
                          getOptionLabel={(option) => t(option.label)}
                          onChange={(event, value) => {
                            window.localStorage.setItem(
                              "year",
                              event.target.value
                            );
                            setYear(value.label);
                          }}
                          renderInput={(params) => (
                            <TextField id="text"
                              {...params}
                              label={t("Year")}
                              name="year"
                              autoComplete="nope"
                              value={year}
                              inputProps={{
                                ...params.inputProps,
                                type: "search",
                                maxLength: 4
                              }}
                              required={true}
                              onChange={(event) => {
                                window.localStorage.setItem("year", event.target.value);
                                setYear(event.target.value);
                              }}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              style={{ marginLeft: "15px", marginTop: "15px", width: "100px" }}
                            />
                          )}
                        />
                      </Stack>
                    </Box>
                  </Box>

                  <Box marginTop={"15px"}>
                    <Grid>
                      {t(
                        "What industry sector does your company serve? Please select or type below."
                      )}
                    </Grid>
                    <Stack sx={{ maxWidth: 235 }}>
                      <Autocomplete
                        style={{ marginTop: "15px" }}
                        freeSolo
                        disableClearable
                        name="industry"
                        options={industryTypes}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(event, value) => {
                          window.localStorage.setItem(
                            "industry",
                            event.target.value
                          );
                          setFieldValue("industry", !window.localStorage.getItem('language') ?
                            (language === "en-US" ? value.label : language === "ru" ? value.labelRus
                              : language === "es-ES" ? value.labelEsp : value.labelUkr) :
                            (window.localStorage.getItem('language') === "en-US" ? value.label
                              : window.localStorage.getItem('language') === "ru" ? value.labelRus
                                : window.localStorage.getItem('language') === "es-ES" ? value.labelEsp : value.labelUkr)
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("Select")}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            name="industry"
                            value={values.industry || industryValue}
                            onChange={(event) => {
                              window.localStorage.setItem(
                                "industry",
                                event.target.value
                              );
                              handleChange(values.industry);
                              setFieldValue("industry", event.target.value);
                            }}
                            error={Boolean(touched.industry && errors.industry)}
                            helperText={touched.industry && errors.industry}
                          />
                        )}
                      />

                      <Grid paddingTop={"10px"} style={{ opacity: "0.4" }}>
                        {t("Please select your industry type")}
                      </Grid>
                    </Stack>
                  </Box>

                  <Box className={classes.checkboxes}>
                    <Grid>
                      {t(
                        "Is your company product or service provider? Please select. *"
                      )}
                    </Grid>
                    <Box display={"flex"}>
                      <Radio
                        name="activity"
                        required={true}
                        checked={!window.localStorage.getItem('language') ?
                          ((language === 'en-US' && selectedProviderValue === "product") ||
                            (language === 'ru' && selectedProviderValue === "продукт") ||
                            (language === 'es-ES' && selectedProviderValue === "producto") ||
                            (language === 'ua' && selectedProviderValue === "продукт")
                          ) :
                          ((window.localStorage.getItem('language') === 'en-US' && selectedProviderValue === "product") ||
                            (window.localStorage.getItem('language') === 'ru' && selectedProviderValue === "продукт") ||
                            (window.localStorage.getItem('language') === 'es-ES' && selectedProviderValue === "producto") ||
                            (window.localStorage.getItem('language') === 'ua' && selectedProviderValue === "продукт")
                          )
                        }
                        onChange={handleCheckboxProviderChange}
                        onClick={(e) => {
                          window.localStorage.setItem(
                            "activity",
                            e.target.value
                          );
                          setFieldValue("activity", e.target.value);
                        }}
                        value={
                          !window.localStorage.getItem('language') ?
                            (language === 'en-US' ? "product" : language === 'ru' ? "продукт"
                              : language === 'es-ES' ? "producto" : "продукт") :
                            (window.localStorage.getItem('language') === 'en-US' ? "product" :
                              window.localStorage.getItem('language') === 'ru' ? "продукт"
                                : window.localStorage.getItem('language') === 'es-ES' ? "producto" : "продукт")
                        }
                      />
                      <Grid className={classes.proper}>{t("Product")}</Grid>
                    </Box>
                    <Box display={"flex"}>
                      <Radio
                        name="activity"
                        required={true}
                        checked={
                          !window.localStorage.getItem('language') ?
                            ((language === 'en-US' && selectedProviderValue === "service") ||
                              (language === 'ru' && selectedProviderValue === "услуги") ||
                              (language === 'es-ES' && selectedProviderValue === "servicio") ||
                              (language === 'ua' && selectedProviderValue === "послуги")
                            ) :
                            ((window.localStorage.getItem('language') === 'en-US' && selectedProviderValue === "service") ||
                              (window.localStorage.getItem('language') === 'ru' && selectedProviderValue === "услуги") ||
                              (window.localStorage.getItem('language') === 'es-ES' && selectedProviderValue === "servicio") ||
                              (window.localStorage.getItem('language') === 'ua' && selectedProviderValue === "послуги")
                            )
                        }
                        onChange={handleCheckboxProviderChange}
                        onClick={(e) => {
                          window.localStorage.setItem(
                            "activity",
                            e.target.value
                          );
                          setFieldValue("activity", e.target.value);
                        }}
                        value={
                          !window.localStorage.getItem('language') ?
                            (language === 'en-US' ? "service" : language === 'ru' ? "услуги"
                              : language === 'es-ES' ? "servicio" : "послуги") :
                            (window.localStorage.getItem('language') === 'en-US' ? "service" :
                              window.localStorage.getItem('language') === 'ru' ? "услуги"
                                : window.localStorage.getItem('language') === 'es-ES' ? "servicio" : "послуги")
                        }
                      />
                      <Grid className={classes.proper}>{t("Service")}</Grid>
                    </Box>
                  </Box>

                  <Box className={classes.checkboxes}>
                    <Grid>
                      {t(
                        "Is your company type is Business to Business or Business to Consumers? Please select. *"
                      )}
                    </Grid>
                    <Box display={"flex"}>
                      <Radio
                        name="companyType"
                        required={true}
                        checked={
                          !window.localStorage.getItem('language') ?
                            (
                              (language === 'en-US' && selectedBusinessValue === "business to business") ||
                              (language === 'ru' && selectedBusinessValue === "бизнесс для бизнесса") ||
                              (language === 'es-ES' && selectedBusinessValue === "empresa a empresa") ||
                              (language === 'ua' && selectedBusinessValue === "бізнес для бізнесу")
                            ) :
                            (
                              (window.localStorage.getItem('language') === 'en-US' && selectedBusinessValue === "business to business") ||
                              (window.localStorage.getItem('language') === 'ru' && selectedBusinessValue === "бизнесс для бизнесса") ||
                              (window.localStorage.getItem('language') === 'es-ES' && selectedBusinessValue === "empresa a empresa") ||
                              (window.localStorage.getItem('language') === 'ua' && selectedBusinessValue === "бізнес для бізнесу")
                            )
                        }
                        onChange={handleCheckboxBusinessChange}
                        onClick={(e) => {
                          window.localStorage.setItem(
                            "companyType",
                            e.target.value
                          );
                          setFieldValue("companyType", e.target.value);
                        }}
                        value={
                          !window.localStorage.getItem('language') ?
                            (language === 'en-US' ? "business to business" : language === 'ru' ? "бизнесс для бизнесса"
                              : language === 'es-ES' ? "empresa a empresa" : "бізнес для бізнесу") :
                            (window.localStorage.getItem('language') === 'en-US' ? "business to business" :
                              window.localStorage.getItem('language') === 'ru' ? "бизнесс для бизнесса"
                                : window.localStorage.getItem('language') === 'es-ES' ? "empresa a empresa" : "бізнес для бізнесу")
                        }
                      />
                      <Grid className={classes.proper}>
                        {t("Business to Business")}
                      </Grid>
                    </Box>
                    <Box display={"flex"}>
                      <Radio
                        name="companyType"
                        required={true}
                        checked={
                          !window.localStorage.getItem('language') ?
                            (
                              (language === 'en-US' && selectedBusinessValue === "business to consumers") ||
                              (language === 'ru' && selectedBusinessValue === "бизнес для потребителей") ||
                              (language === 'es-ES' && selectedBusinessValue === "negocio a los consumidores") ||
                              (language === 'ua' && selectedBusinessValue === "бізнес для споживачів")
                            ) :
                            (
                              (window.localStorage.getItem('language') === 'en-US' && selectedBusinessValue === "business to consumers") ||
                              (window.localStorage.getItem('language') === 'ru' && selectedBusinessValue === "бизнес для потребителей") ||
                              (window.localStorage.getItem('language') === 'es-ES' && selectedBusinessValue === "negocio a los consumidores") ||
                              (window.localStorage.getItem('language') === 'ua' && selectedBusinessValue === "бізнес для споживачів")
                            )
                        }
                        onChange={handleCheckboxBusinessChange}
                        onClick={(e) => {
                          window.localStorage.setItem(
                            "companyType",
                            e.target.value
                          );
                          setFieldValue("companyType", e.target.value);
                        }}
                        value={
                          !window.localStorage.getItem('language') ?
                            (language === 'en-US' ? "business to consumers" : language === 'ru' ? "бизнес для потребителей"
                              : language === 'es-ES' ? "negocio a los consumidores" : "бізнес для споживачів") :
                            (window.localStorage.getItem('language') === 'en-US' ?
                              "business to consumers" : window.localStorage.getItem('language') === 'ru' ?
                                "бизнес для потребителей" : window.localStorage.getItem('language') === 'es-ES'
                                  ? "negocio a los consumidores" : "бізнес для споживачів")
                        }
                      />
                      <Grid className={classes.proper}>
                        {t("Business to Consumers")}
                      </Grid>
                    </Box>
                  </Box>
                  <Box marginTop={"15px"}>
                    <Grid>
                      {t(
                        "Who are your customers? (Dental Offices, General Public, Industrial Companies etc.)"
                      )}
                    </Grid>
                    <TextField
                      id="text"
                      label={t("Customers")}
                      name="customers"
                      autoComplete="off"
                      value={values.customers}
                      onChange={(event) => {
                        window.localStorage.setItem(
                          "customers",
                          event.target.value
                        );
                        handleChange(values.customers);
                        setFieldValue("customers", event.target.value);
                      }}
                      error={Boolean(touched.customers && errors.customers)}
                      helperText={touched.customers && errors.customers}
                      style={{ marginTop: "15px" }}
                    />
                  </Box>
                  <Box marginTop={"15px"}>
                    <Grid>
                      {t(
                        "Please describe your product or service. (We are making specialty bottle plugs.)"
                      )}
                    </Grid>
                    <TextField
                      id="text"
                      label={t("Description")}
                      multiline
                      name="description"
                      autoComplete="off"
                      value={values.description}
                      onChange={(event) => {
                        window.localStorage.setItem(
                          "description",
                          event.target.value
                        );
                        handleChange(values.description);
                        setFieldValue("description", event.target.value);
                      }}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      style={{ marginTop: "15px" }}
                    />
                  </Box>
                  <Box marginTop={"15px"}>
                    <Grid>
                      {t(
                        "What are your recent accomplishments? (We just opened or We received 100 orders etc.)"
                      )}
                    </Grid>
                    <TextField
                      id="text"
                      label={t("Accomplishments")}
                      name="accomplishments"
                      autoComplete="off"
                      value={values.accomplishments}
                      onChange={(event) => {
                        window.localStorage.setItem(
                          "accomplishments",
                          event.target.value
                        );
                        handleChange(values.accomplishments);
                        setFieldValue("accomplishments", event.target.value);
                      }}
                      error={Boolean(
                        touched.accomplishments && errors.accomplishments
                      )}
                      helperText={
                        touched.accomplishments && errors.accomplishments
                      }
                      style={{ marginTop: "15px" }}
                    />
                  </Box>
                  <Box marginTop={"15px"}>
                    <Grid>
                      {t(
                        "What is your goal? (We would like to take 2% of the market)"
                      )}
                    </Grid>
                    <TextField
                      id="text"
                      label={t("Goal")}
                      name="goal"
                      autoComplete="off"
                      value={values.goal}
                      onChange={(event) => {
                        window.localStorage.setItem("goal", event.target.value);
                        handleChange(values.goal);
                        setFieldValue("goal", event.target.value);
                      }}
                      error={Boolean(touched.goal && errors.goal)}
                      helperText={touched.goal && errors.goal}
                      style={{ marginTop: "15px" }}
                    />
                  </Box>
                  <Box marginTop={"15px"}>
                    <Grid>
                      {t(
                        "What are you looking for (Your needs)? (Wile by FTC regulations you are not allowed to solicit money directly on this site you may say We need help with marketing, distribution etc.)"
                      )}
                    </Grid>
                    <TextField
                      id="text"
                      label={t("Your needs")}
                      name="yourNeeds"
                      autoComplete="off"
                      value={values.yourNeeds}
                      onChange={(event) => {
                        window.localStorage.setItem(
                          "yourNeeds",
                          event.target.value
                        );
                        handleChange(values.yourNeeds);
                        setFieldValue("yourNeeds", event.target.value);
                      }}
                      error={Boolean(touched.yourNeeds && errors.yourNeeds)}
                      helperText={touched.yourNeeds && errors.yourNeeds}
                      style={{ marginTop: "15px" }}
                    />
                  </Box>



                  <Box marginTop={"15px"}>
                    <Grid>
                      {t("Please provide your phone number (213-949-1111)")}
                    </Grid>
                    <TextField
                      id="text"
                      label={t("Phone number")}
                      name="tel"
                      autoComplete="off"
                      value={tel}
                      onChange={(event) => {
                        window.localStorage.setItem("tel", event.target.value);
                        setTel(maskPhone(event.target.value));
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      error={Boolean(touched.tel && errors.tel)}
                      helperText={touched.tel && errors.tel}
                      style={{ marginTop: "15px" }}
                    />
                  </Box>

                  <Box marginTop={"15px"}>
                    <Grid>{t("Please provide your website.")}</Grid>
                    <TextField
                      id="text"
                      label="www.example.com"
                      name="website"
                      autoComplete="off"
                      value={website}
                      onChange={(event) => {
                        window.localStorage.setItem(
                          "website",
                          event.target.value
                        );
                        setWebsite(event.target.value);
                      }}
                      style={{ marginTop: "15px" }}
                    />
                  </Box>

                  <Box marginTop={"15px"}>
                    <Grid>
                      {t(
                        "Please enter your email address. We will send you a link to your files, using this address"
                      )}
                    </Grid>
                    <TextField
                      id="email"
                      label={t("Email Address")}
                      name="email"
                      autoComplete="email"
                      value={values.email}
                      onChange={(event) => {
                        window.localStorage.setItem(
                          "email",
                          event.target.value
                        );
                        handleChange(values.email);
                        setFieldValue("email", event.target.value);
                      }}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      style={{ marginTop: "15px" }}
                    />
                  </Box>
                  <Box
                    p={3}
                    display={"flex"}
                    justifyContent={"center"}
                    paddingBottom={"0px"}
                  >
                    <Link
                      to="/stepOne"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.button}
                        onClick={() => handleBack()}
                      >
                        {t("Prev Step")}
                      </Button>
                    </Link>

                    {saveButton && (
                      <Button
                        style={{ marginLeft: "20px" }}
                        variant="contained"
                        type="submit"
                        size="large"
                        onClick={() => {
                          handleSubmit(
                            values.salutation,
                            values.activity,
                            values.companyType
                          );
                          Object.values(values).every((el) => el !== "") &&
                            values.email.match(emailRegExp) && month !== "" && year !== "" &&
                            setSave(true);
                          Object.values(values).every((el) => el !== "") &&
                            values.email.match(emailRegExp) && month !== "" && year !== "" &&
                            setSaveButton(false);
                        }}
                        className={classes.button}
                      >
                        {t("Save")}
                      </Button>
                    )}

                    {save && (
                      <Link
                        to={
                          !stepKeywords ? "/stepTwoFullPackage" : "/stepThreeFullPackage"
                        }
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <Button
                          variant="contained"
                          disabled={!stepKeywords}
                          size="large"
                          style={{ marginLeft: "20px" }}
                          className={classes.button}
                          onClick={() => {
                            setFullPackage(true);
                            fullPackageFunction();
                            handleNext();
                          }}
                        >
                          {t("Next Step")}
                        </Button>
                      </Link>
                    )}
                  </Box>
                </Box>
              </motion.div>
            </Form>
          )}
        </Formik>
      </Box>
    );
  }

  return <span></span>;
};
export default StepTwo;
