import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Copyright from "./Components/Copyright";
import NavBar from "./Components/NavBar/NavBar";
import MobileNavigation from "./Components/MobileNavigation/MobileNavigation";
import Home from "./Pages/HomePage/Home";
import StepOne from "./Pages/StepOnePage/StepOne";
import StepTwo from "./Pages/StepTwoPage/StepTwo";
import StepTwoFullPackagePage from "./Pages/StepTwoFullPackagePage";
import StepThree from "./Pages/StepThreePage/StepThree";
import StepThreeFullPackage from "./Pages/StepThreeFullPackagePage/StepThreeFullPackage";
import StepFour from "./Pages/StepFourPage/StepFour";
import StepFive from "./Pages/StepFivePage/StepFive";
import About from "./Pages/AboutPage/About";
import Login from "./Pages/LoginPage/Login";
import SignUp from "./Pages/SignUpPage/SignUp";
import SignUpW9 from "./Pages/SignUpW9Page/SignUpW9";
import HowItWork from "./Pages/HowItWorkPage/HowItWork";
import StepForm from "./Components/StepForm";
import UserPage from "./Pages/UserPage";
import PayPal from "./Pages/PayPalPage/PayPal";

const useStyles = makeStyles({
  container: {
    background: "#ffffff",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "16px",
    animation: "background 4s infinite alternate",
  },
});

function App() {
  const classes = useStyles();

  const [openNav, setOpenNav] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [stepForm, setStepForm] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fullPackage, setFullPackage] = useState(false);
  const [stepImage, setStepImage] = useState(null);
  const [stepVideo, setStepVideo] = useState(null);
  const [isVideo, setIsVideo] = useState(null);
  const [files, setFiles] = useState([]);
  const [language, setLanguage] = useState("en-US");
  const [fileNames, setFileNames] = useState([]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };
  return (
    <Box className={classes.container}>
      <Box>
        <BrowserRouter>
          {openNav && (
            <MobileNavigation
              setOpenNav={setOpenNav}
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              setLanguage={setLanguage}
            />
          )}
          <NavBar
            openNav={openNav}
            setOpenNav={setOpenNav}
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            language={language}
            setLanguage={setLanguage}
          />
          <Routes>
            <Route
              path="/"
              element={
                <Home setStepForm={setStepForm} isLoggedIn={isLoggedIn} />
              }
            />
            <Route
              path="/about"
              element={<About setStepForm={setStepForm} />}
            />
            <Route
              path="/howItWork"
              element={<HowItWork setStepForm={setStepForm} />}
            />
            <Route
              path="/login"
              element={
                <Login
                  setStepForm={setStepForm}
                  setIsLoggedIn={setIsLoggedIn}
                />
              }
            />
            <Route
              path="/signUp"
              element={
                <SignUp
                  setStepForm={setStepForm}
                  setIsLoggedIn={setIsLoggedIn}
                />
              }
            />
            <Route
              path="/signUpW9"
              element={
                <SignUpW9
                  setStepForm={setStepForm}
                  setIsLoggedIn={setIsLoggedIn}
                />
              }
            />
            <Route
              path="/userPage"
              element={
                <UserPage
                  setStepForm={setStepForm}
                  setIsLoggedIn={setIsLoggedIn}
                  stepVideo={stepVideo}
                  setStepVideo={setStepVideo}
                />
              }
            />
            <Route
              path="/stepOne"
              element={
                <StepOne
                  handleNext={handleNext}
                  setStepForm={setStepForm}
                  fullPackage={fullPackage}
                  setFullPackage={setFullPackage}
                  setStepImage={setStepImage}
                  files={files}
                  setFiles={setFiles}
                  fileNames={fileNames}
                  setFileNames={setFileNames}
                  isLoggedIn={isLoggedIn}
                />
              }
            />
            <Route
              path="/stepTwo"
              element={
                <StepTwo
                  handleNext={handleNext}
                  handleBack={handleBack}
                  stepImage={stepImage}
                  setStepImage={setStepImage}
                  isVideo={isVideo}
                  setIsVideo={setIsVideo}
                  language={language}
                  fileNames={fileNames}
                  isLoggedIn={isLoggedIn}
                />
              }
            />
            <Route
              path="/stepTwoFullPackage"
              element={
                <StepTwoFullPackagePage
                  handleNext={handleNext}
                  handleBack={handleBack}
                  fullPackage={fullPackage}
                  setFullPackage={setFullPackage}
                  stepImage={stepImage}
                  setStepImage={setStepImage}
                  isVideo={isVideo}
                  setIsVideo={setIsVideo}
                  language={language}
                  fileNames={fileNames}
                  isLoggedIn={isLoggedIn}
                />
              }
            />

            <Route
              path="/stepThree"
              element={
                <StepThree
                  handleNext={handleNext}
                  handleBack={handleBack}
                  fullPackage={fullPackage}
                  setFullPackage={setFullPackage}
                  isLoggedIn={isLoggedIn}
                  stepImage={stepImage}
                  setStepImage={setStepImage}
                  isVideo={isVideo}
                  setIsVideo={setIsVideo}
                  language={language}
                  fileNames={fileNames}
                />
              }
            />
            <Route
              path="/stepThreeFullPackage"
              element={
                <StepThreeFullPackage
                  handleNext={handleNext}
                  handleBack={handleBack}
                  fullPackage={fullPackage}
                  setFullPackage={setFullPackage}
                  isLoggedIn={isLoggedIn}
                  stepImage={stepImage}
                  setStepImage={setStepImage}
                  isVideo={isVideo}
                  setIsVideo={setIsVideo}
                  language={language}
                  fileNames={fileNames}
                />
              }
            />
            <Route
              path="/stepFour"
              element={
                <StepFour
                  fullPackage={fullPackage}
                  handleNext={handleNext}
                  setStepForm={setStepForm}
                  handleBack={handleBack}
                  setActiveStep={setActiveStep}
                  isLoggedIn={isLoggedIn}
                  stepVideo={stepVideo}
                  setStepVideo={setStepVideo}
                  isVideo={isVideo}
                  setIsVideo={setIsVideo}
                />
              }
            />
            <Route
              path="/stepFive"
              element={<StepFive setStepForm={setStepForm} />}
            />
            <Route
              path="/PayPal"
              element={
                <PayPal
                  setStepForm={setStepForm}
                  setIsLoggedIn={setIsLoggedIn}
                />
              }
            />
          </Routes>
          {stepForm && (
            <StepForm
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setStepForm={setStepForm}
            />
          )}
        </BrowserRouter>
      </Box>
      <Copyright />
    </Box>
  );
}

export default App;
