export const industryTypes = [
  {
    value: "Aerospace",
    label: "Aerospace",
    labelRus: "Аэрокосмическая промышленность", 
    labelEsp: "Aeroespacial",
    labelUkr: "Аерокосмічна промисловість",
  },
  {
    value: "Aerospace and Defense",
    label: "Aerospace and Defense",
    labelRus: "Аэрокосмическая промышленность и оборона",
    labelEsp: "Aeroespacial y Defensa",
    labelUkr: "Аерокосмічна промисловість та оборона",
  },
  {
    value: "Airlines",
    label: "Airlines",
    labelRus: "Авиакомпании",
    labelEsp: "Aerolíneas",
    labelUkr: "Авіакомпанії",
  },
  {
    value: "Aluminum",
    label: "Aluminum",
    labelRus: "Алюминий",
    labelEsp: "Aluminio",
    labelUkr: "Алюміній",
  },
  {
    value: "Apparel Retailers",
    label: "Apparel Retailers",
    labelRus: "Магазины одежды",
    labelEsp: "Minoristas de ropa",
    labelUkr: "Магазини одягу",
  },
  {
    value: "Asset Managers",
    label: "Asset Managers",
    labelRus: "Управление активами",
    labelEsp: "Administradores de activos",
    labelUkr: "Управління активами",
  },
  {
    value: "Automobiles",
    label: "Automobiles",
    labelRus: "Автомобили",
    labelEsp: "Automóviles",
    labelUkr: "Автомобілі",
  },
  {
    value: "Auto Parts",
    label: "Auto Parts",
    labelRus: "Автозапчасти",
    labelEsp: "Autopartes",
    labelUkr: "Автозапчастини",
  },
  {
    value: "Banks",
    label: "Banks",
    labelRus: "Банки",
    labelEsp: "Bancos",
    labelUkr: "Банки",
  },
  {
    value: "Basic Resources",
    label: "Basic Resources",
    labelRus: "Основные ресурсы",
    labelEsp: "Recursos Básicos",
    labelUkr: "Основні ресурси",
  },
  {
    value: "Beverages",
    label: "Beverages",
    labelRus: "Напитки",
    labelEsp: "Bebidas",
    labelUkr: "Напої",
  },
  {
    value: "Biotechnology",
    label: "Biotechnology",
    labelRus: "Биотехнология",
    labelEsp: "Biotecnología",
    labelUkr: "Біотехнологія",
  },
  {
    value: "Bldg Materials and Fixtures",
    label: "Bldg Materials and Fixtures",
    labelRus: "Строительные материалы и приспособления",
    labelEsp: "Materiales y accesorios del edificio",
    labelUkr: "Будівельні матеріали та пристрої",
  },
  {
    value: "Brewers",
    label: "Brewers",
    labelRus: "Пивовары",
    labelEsp: "Cerveceros",
    labelUkr: "Пивовари",
  },
  {
    value: "Broadcst and Entertainment",
    label: "Broadcst and Entertainment",
    labelRus: "Телерадиовещание и развлечения",
    labelEsp: "Radiodifusión y Entretenimiento",
    labelUkr: "Телерадіомовлення та розваги",
  },
  {
    value: "Broadline Retailers",
    label: "Broadline Retailers",
    labelRus: "Розничные продавцы",
    labelEsp: "Minoristas de línea ancha",
    labelUkr: "Роздрібні продавці",
  },
  {
    value: "Business Support Services",
    label: "Business Support Services",
    labelRus: "Услуги поддержки бизнеса",
    labelEsp: "Servicios de apoyo comercial",
    labelUkr: "Послуги підтримки бізнесу",
  },
  {
    value: "Business Training",
    label: "Business Training",
    labelRus: "Бизнес-тренинг",
    labelEsp: "Capacitación del trabajo",
    labelUkr: "Бізнес-тренінг",
  },
  {
    value: "Chemicals",
    label: "Chemicals",
    labelRus: "Химикаты",
    labelEsp: "Quimicos",
    labelUkr: "Хімікати",
  },
  {
    value: "Clothing and Accessories",
    label: "Clothing and Accessories",
    labelRus: "Одежда и аксессуары",
    labelEsp: "Ropa y accesorios",
    labelUkr: "Одяг та аксесуари",
  },
  {
    value: "Coal",
    label: "Coal",
    labelRus: "Уголь",
    labelEsp: "Carbón",
    labelUkr: "Вугілля",
  },
  {
    value: "Commercial Vehicles",
    label: "Commercial Vehicles",
    labelRus: "Коммерческие автомобили",
    labelEsp: "Vehículos Comerciales",
    labelUkr: "Комерційні автомобілі",
  },
  {
    value: "Commodity Chemicals",
    label: "Commodity Chemicals",
    labelRus: "Товарные химикаты",
    labelEsp: "Productos Químicos Básicos",
    labelUkr: "Товарні хімікати",
  },
  {
    value: "Computer Hardware",
    label: "Computer Hardware",
    labelRus: "Компьютерное железо",
    labelEsp: "Hardware de la computadora",
    labelUkr: "Комп'ютерне залізо",
  },
  {
    value: "Computer Services",
    label: "Computer Services",
    labelRus: "Компьютерные услуги",
    labelEsp: "Servicios Informáticos",
    labelUkr: "Комп'ютерні послуги",
  },
  {
    value: "Construction and Materials",
    label: "Construction and Materials",
    labelRus: "Строительство и материалы",
    labelEsp: "Construcción y Materiales",
    labelUkr: "Будівництво та матеріали",
  },
  {
    value: "Consumer Finance",
    label: "Consumer Finance",
    labelRus: "Потребительские финансы",
    labelEsp: "Financiamiento al consumo",
    labelUkr: "Споживчі фінанси",
  },
  {
    value: "Containers and Packaging",
    label: "Containers and Packaging",
    labelRus: "Тара и упаковка",
    labelEsp: "Envases y Empaques",
    labelUkr: "Тара та упаковка",
  },
  {
    value: "Conventional Electricity",
    label: "Conventional Electricity",
    labelRus: "Обычное электричество",
    labelEsp: "Electricidad Convencional",
    labelUkr: "Звичайна електрика",
  },
  {
    value: "Defense",
    label: "Defense",
    labelRus: "Защита",
    labelEsp: "Defensa",
    labelUkr: "Захист",
  },
  {
    value: "Delivery Services",
    label: "Delivery Services",
    labelRus: "Услуги доставки",
    labelEsp: "Servicios de Entrega",
    labelUkr: "Послуги доставки",
  },
  {
    value: "Distillers and Vintners",
    label: "Distillers and Vintners",
    labelRus: "Дистилляторы и виноделы",
    labelEsp: "Destiladores y Viticultores",
    labelUkr: "Дистилятори та винороби",
  },
  {
    value: "Diversified Industrials",
    label: "Diversified Industrials",
    labelRus: "Диверсифицированные промышленные предприятия",
    labelEsp: "Industriales Diversificados",
    labelUkr: "Диверсифіковані промислові підприємства",
  },
  {
    value: "Diversified Real-estates",
    label: "Diversified Real-estates",
    labelRus: "Диверсифицированная недвижимость",
    labelEsp: "Bienes Raíces Diversificados",
    labelUkr:  "Диверсифікована нерухомість",
  },
  {
    value: "Electricity",
    label: "Electricity",
    labelRus: "Электричество",
    labelEsp: "Electricidad",
    labelUkr: "Електрика",
  },
  {
    value: "Electronic Equipment",
    label: "Electronic Equipment",
    labelRus: "Электронное оборудование",
    labelEsp: "Equipo Electronico",
    labelUkr: "Електронне устаткування",
  },
  {
    value: "Exploration and Production",
    label: "Exploration and Production",
    labelRus: "Разведка и добыча",
    labelEsp: "Exploración y Producción",
    labelUkr: "Розвідка та видобуток",
  },
  {
    value: "Financial Administration",
    label: "Financial Administration",
    labelRus: "Финансовое управление",
    labelEsp: "Administración Financiera",
    labelUkr: "Фінансове управління",
  },
  {
    value: "Fixed Line Telecommunication",
    label: "Fixed Line Telecommunication",
    labelRus: "Фиксированная связь",
    labelEsp: "Telecomunicaciones de Línea Fija",
    labelUkr: "Фіксований зв'язок",
  },
  {
    value: "Food Producers",
    label: "Food Producers",
    labelRus: "Производители продуктов питания",
    labelEsp: "Productores de Alimentos",
    labelUkr: "Виробники продуктів харчування",
  },
  {
    value: "Footwear",
    label: "Footwear",
    labelRus: "Обувь",
    labelEsp: "Calzado",
    labelUkr: "Взуття",
  },
  {
    value: "Furnishings",
    label: "Furnishings",
    labelRus: "Обстановка",
    labelEsp: "Mobiliario",
    labelUkr: "Обстановка",
  },
  {
    value: "Gambling",
    label: "Gambling",
    labelRus: "Aзартные игры",
    labelEsp: "Juego",
    labelUkr: "Азартні ігри",
  },
  {
    value: "Gas Distribution",
    label: "Gas Distribution",
    labelRus: "Распределение газа",
    labelEsp: "Distribución de gas",
    labelUkr: "Розподіл газу",
  },
  {
    value: "General Industrials",
    label: "General Industrials",
    labelRus: "Общие промышленные предприятия",
    labelEsp: "Industrias Generales",
    labelUkr: "Загальні промислові підприємства",
  },
  {
    value: "Gold Mining",
    label: "Gold Mining",
    labelRus: "Добыча золота",
    labelEsp: "Minería de oro",
    labelUkr: "Видобуток золота",
  },
  {
    value: "Health Care Equip and Services",
    label: "Health Care Equip and Services",
    labelRus: "Медицинское оборудование и услуги",
    labelEsp: "Equipos y servicios de atención médica",
    labelUkr: "Медичне обладнання та послуги",
  },
  {
    value: "Heavy Construction",
    label: "Heavy Construction",
    labelRus: "Тяжелые конструкции",
    labelEsp: "Construcción Pesada",
    labelUkr: "Важкі конструкції",
  },
  {
    value: "Home Construction",
    label: "Home Construction",
    labelRus: "Строительство домов",
    labelEsp: "Construcción de Viviendas",
    labelUkr: "Будівництво будинків",
  },
  {
    value: "Hotels",
    label: "Hotels",
    labelRus: "Отели",
    labelEsp: "Hoteles",
    labelUkr: "Готелі", 
  },
  {
    value: "Industrial Engineering",
    label: "Industrial Engineering",
    labelRus: "Промышленная инженерия",
    labelEsp: "Ingeniería Industrial",
    labelUkr: "Промислова інженерія",
  },
  {
    value: "Industrial Goods and Services",
    label: "Industrial Goods and Services",
    labelRus: "Промышленные товары и услуги",
    labelEsp: "Bienes y Servicios Industriales",
    labelUkr: "Промислові товари та послуги", 
  },
  {
    value: "Integrated Oil and Gas",
    label: "Integrated Oil and Gas",
    labelRus: "Интегрированные Нефть и Газ",
    labelEsp: "Petróleo y Gas Integrados",
    labelUkr: "Інтегровані Нафта та Газ",
  },
  {
    value: "Internet",
    label: "Internet",
    labelRus: "Интернет",
    labelEsp: "Internet",
    labelUkr: "Інтернет",
  },
  {
    value: "Investment Services",
    label: "Investment Services",
    labelRus: "Инвестиционные услуги",
    labelEsp: "Servicios de Inversión",
    labelUkr: "Інвестиційні послуги",
  },
  {
    value: "Media",
    label: "Media",
    labelRus: "Средства массовой информации",
    labelEsp: "Medios de Comunicación",
    labelUkr: "Засоби масової інформації",
  },
  {
    value: "Medical Supplies",
    label: "Medical Supplies",
    labelRus: "Медикаменты",
    labelEsp: "Suministros Médicos",
    labelUkr: "Медикаменти",
  },
  {
    value: "Mining",
    label: "Mining",
    labelRus: "Добыча полезных ископаемых",
    labelEsp: "Minería",
    labelUkr: "Видобуток корисних копалин", 
  },
  {
    value: "Mobile Telecommunication",
    label: "Mobile Telecommunication",
    labelRus: "Мобильная связь",
    labelEsp: "Telecomunicaciones Móviles",
    labelUkr: "Мобільний зв'язок",
  },
  {
    value: "Multiutilities",
    label: "Multiutilities",
    labelRus: "Мультиутилиты",
    labelEsp: "Multiutilidades",
    labelUkr: "Мультіутіліти",
  },
  {
    value: "Nonferrous Metals",
    label: "Nonferrous Metals",
    labelRus: "Цветные металлы",
    labelEsp: "Metales no Ferrosos",
    labelUkr: "Кольорові метали",
  },
  {
    value: "Paper",
    label: "Paper",
    labelRus: "Бумага",
    labelEsp: "Papel",
    labelUkr: "Папір",
  },
  {
    value: "Personal Products",
    label: "Personal Products",
    labelRus: "Персональные продукты",
    labelEsp: "Productos Personales",
    labelUkr: "Персональні продукти", 
  },
  {
    value: "Publishing",
    label: "Publishing",
    labelRus: "Издательство",
    labelEsp: "Publicación",
    labelUkr: "Видавництво",
  },
  {
    value: "Railroads",
    label: "Railroads",
    labelRus: "Железные дороги",
    labelEsp: "Ferrocarriles",
    labelUkr: "Залізниці",
  },
  {
    value: "Real Estate",
    label: "Real Estate",
    labelRus: "Недвижимость",
    labelEsp: "Bienes Raíces",
    labelUkr: "Нерухомість",
  },
  {
    value: "Reinsurance",
    label: "Reinsurance",
    labelRus: "Перестрахование",
    labelEsp: "Reaseguro",
    labelUkr: "Перестрахування",
  },
  {
    value: "Semiconductor",
    label: "Semiconductor",
    labelRus: "Полупроводники",
    labelEsp: "Semiconductor",
    labelUkr: "Напівпровідники",
  },
  {
    value: "Soft Drinks",
    label: "Soft Drinks",
    labelRus: "Безалкогольные напитки",
    labelEsp: "Bebidas sin alcohol",
    labelUkr: "Безалкогольні напої", 
  },
  {
    value: "Software",
    label: "Software",
    labelRus: "Программное обеспечение",
    labelEsp: "Software",
    labelUkr: "Програмне забезпечення",
  },
  {
    value: "Steel",
    label: "Steel",
    labelRus: "Сталь",
    labelEsp: "Acero",
    labelUkr: "Сталь",
  },
  {
    value: "Support Services",
    label: "Support Services",
    labelRus: "Службы поддержки",
    labelEsp: "Servicios de apoyo",
    labelUkr: "Служби підтримки",
  },
  {
    value: "Technology Equipment",
    label: "Technology Equipment",
    labelRus: "Технологическое оборудование",
    labelEsp: "Equipo de tecnología",
    labelUkr: "Технологічне обладнання",
  },
  {
    value: "Telecommunicatin Equipment",
    label: "Telecommunicatin Equipment",
    labelRus: "Телекоммуникационное оборудование",
    labelEsp: "Equipos de telecomunicaciones",
    labelUkr: "Телекомунікаційне обладнання",
  },
  {
    value: "Tires",
    label: "Tires",
    labelRus: "Шины",
    labelEsp: "Neumáticos",
    labelUkr: "Шини",
  },
  {
    value: "Tobacco",
    label: "Tobacco",
    labelRus: "Табак",
    labelEsp: "Tabaco",
    labelUkr: "Табак",
  },
  {
    value: "Toys",
    label: "Toys",
    labelRus: "Игрушки",
    labelEsp: "Juguetes",
    labelUkr: "Іграшки",
  },
  {
    value: "Transportation Services",
    label: "Transportation Services",
    labelRus: "Транспортные услуги",
    labelEsp: "Servicios de transporte",
    labelUkr: "Транспортні послуги",  
  },
  {
    value: "Travel and Tourism",
    label: "Travel and Tourism",
    labelRus: "Путешествие и туризм",
    labelEsp: "Viaje y Turismo",
    labelUkr: "Подорож та туризм",
  },
  {
    value: "Trucking",
    label: "Trucking",
    labelRus: "Грузоперевозки",
    labelEsp: "Camionaje",
    labelUkr: "Вантажоперевезення",
  },
  {
    value: "Waste and Disposal Services",
    label: "Waste and Disposal Services",
    labelRus: "Услуги по утилизации и удалению отходов",
    labelEsp: "Servicios de Residuos y Eliminación",
    labelUkr: "Послуги з утилізації та видалення відходів",
  },
  {
    value: "Water",
    label: "Water",
    labelRus: "Вода",
    labelEsp: "Agua",
    labelUkr: "Вода",
  },
  {
    value: "Other",
    label: "Other",
    labelRus: "Другое",
    labelEsp: "Otro",
    labelUkr: "інше",
  },
];
