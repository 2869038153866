import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const accessToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJUZW5uaXNMaXZlIiwibmFtZSI6ImFwaV90b2tlbiIsImlhdCI6MTYwNTU1NTE1MywiZXhwIjoxODkzNDU1OTk5fQ.rvYxT8eIPbitqrMEnCImJPaOig-BsfqF6s6tkDJiCOQ";

const initialState = {
  videos: [],
  status: null,
  error: null,
  token: `Bearer ${accessToken}`,
};

const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

export const addNewData = createAsyncThunk(
  "video/fetchVideo",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        "https://thestartupreport.net/api/v1.0/api.php",
        data,
        config
      );
      if (response.status === 200) {
        localStorage.setItem("token", accessToken);
        let result = data.data;
        localStorage.setItem("lastCreateProject", JSON.stringify(result));
        localStorage.setItem(
          "project_id",
          JSON.parse(response.data?.result?.[0]?.id)
        );
        localStorage.setItem("text", response.data?.result?.[0]?.pitch);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const dataSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    deleteData(state) {
      state.videos = state.videos.filter(
        (el) => el === {}
      );
    },
  },
  extraReducers(builder) {
    builder.addCase(addNewData.fulfilled, (state, action) => {
      state.videos.push(action.payload);
    });
  },
});

export const { videoAdded } = dataSlice.actions;
export const { deleteData } = dataSlice.actions;

export default dataSlice.reducer;
