import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const accessToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJUZW5uaXNMaXZlIiwibmFtZSI6ImFwaV90b2tlbiIsImlhdCI6MTYwNTU1NTE1MywiZXhwIjoxODkzNDU1OTk5fQ.rvYxT8eIPbitqrMEnCImJPaOig-BsfqF6s6tkDJiCOQ";

const initialState = {
  profile: [],
  status: null,
  error: null,
  token: `Bearer ${accessToken}`,
};

const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

export const getProfileData = createAsyncThunk(
  "video/fetchVideo",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        "https://thestartupreport.net/api/v1.0/api.php",
        data,
        config
      );
      if (response.status === 200) {
        localStorage.setItem("token", accessToken);
        let result = response.data.result
        localStorage.setItem("profile", JSON.stringify(result));
        return response.data.result;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getProfileData.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
  },
});

export const { videoAdded } = profileSlice.actions;

export default profileSlice.reducer;
