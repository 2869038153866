import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import bg from "../../assets/images/header.png";
import { useStyles } from "./styles";
import { handleCleanStorage } from "./data";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sendFinalEmail } from "../../redux/slices/finalEmailSlice";
import Loader from "../../Components/Loader/Loader";
import Alert from '@mui/material/Alert';

const StepFour = ({
  handleNext,
  handleBack,
  setStepForm,
  fullPackage,
  isLoggedIn,
  stepVideo,
  setStepVideo,
  isVideo,
  setIsVideo,
}) => {
  
  const classes = useStyles();

  const dispatch = useDispatch();

  const { t, ready } = useTranslation();

  useEffect(() => {
    JSON.parse(window.localStorage.getItem("project_id"));
  }, []);

  useEffect(() => {
    setStepVideo(window.localStorage.getItem("video"));
  }, [setStepVideo]);

  useEffect(() => {
    setIsVideo(window.localStorage.getItem("is_video"));
  }, [setIsVideo]);

  const user = useSelector((state) => state.userReducer.username);

  const sendFinalMessage = () => {
    const body = {
      action: "send_final_email",
      project_id: JSON.parse(window.localStorage.getItem("project_id")),
    };
    if (user) {
      dispatch(sendFinalEmail(body));
    }
  };

  const videoDemo = useSelector(
    (state) => state.doVideoReducer?.videos?.result?.video?.url
  );

  if (ready) {
    return (
      <Box>
        <img
          src={bg}
          alt="header"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
        {!isLoggedIn &&
          <Alert severity="warning"> {t("You are in the demo mode")}</Alert>
        }
        <motion.div
          initial={{ width: 0 }}
          animate={{
            width: "100%",
            transition: {
              transitionProperty: "max-height",
              transitionDuration: "0.5s",
              transitionTimingFunction: "ease-in",
              transitionDelay: "0s",
            },
          }}
        >
          <Box p={4}>
            <hr />
            <Typography className={classes.step} variant="h5">
              {t("Step Four")}
            </Typography>
            <hr />
            <Grid paddingTop={"20px"}>
              {t("Enjoy preview of your creaton!")}
            </Grid>

            <Box className={classes.videoPlayer}>
              {!videoDemo ? (
                <Box
                  style={{ height: "250px" }}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Loader />
                </Box>
              ) : (
                <ReactPlayer
                  style={{ border: "5px solid #96b0c1", borderRadius: "10px", backgroundColor: "#000000" }}
                  height="inherit"
                  url={videoDemo}
                  controls={true}
                />
              )}
            </Box>

            <Box p={3} className={classes.buttons} paddingBottom={"0px"}>
              <Link
                to={videoDemo ? "/stepThree" : "/stepFour"}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  style={{ fontSize: "10px" }}
                  className={classes.firstStepButton}
                  disabled={!videoDemo}
                  onClick={() => handleBack()}
                >
                  {t("Press here if you need to make any changes")}
                </Button>
              </Link>

              <Link
                to={videoDemo ? "/stepFive" : "/stepFour"}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  style={{ marginLeft: "20px", fontSize: "10px" }}
                  disabled={!videoDemo}
                  className={classes.stepButton}
                  onClick={() => {
                    handleNext();
                    sendFinalMessage();
                    setStepForm(false);
                    handleCleanStorage();
                  }}
                >
                  {isLoggedIn
                    ? t("Next Step")
                    : t("Next step without registration")}
                </Button>
              </Link>

              {!isLoggedIn && (
                <Link
                  to="/login"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    style={{ marginLeft: "20px", fontSize: "10px" }}
                    className={classes.stepButton}
                  >
                    {t("Singn up and Continue")}
                  </Button>
                </Link>
              )}
            </Box>
          </Box>
        </motion.div>
      </Box>
    );
  }
  return <span></span>;
};

export default StepFour;
