import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    step: {
      textTransform: "uppercase",
    },
    videoSelect: {
      marginTop: "30px",
    },
    videoStyle: {
      marginTop: "13px",
    },
    button: {
      "@media (max-width:780px)": {
        fontSize: "10px !important",
      },
    },
  });