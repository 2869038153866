import React from "react";
import { Box, Link, Typography } from "@mui/material";

const Copyright = (props) => {
  return (
    <>
      {window.location.pathname !== "/PayPal" && (
        <Box p={2}>
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
          >
            {"Copyright © "}
            <Link color="inherit" href="http://www.perfectinvention.com">
              Perfect Invention,
            </Link>
            {`LLC 2016-${new Date().getFullYear()}.`}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Copyright;
