import React from "react";
import LanguageIcon from "@mui/icons-material/Language";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";

const LangMenu = ({ setLanguage }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ITEM_HEIGHT = 47;

  return (
    <div>
      <LanguageIcon
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            changeLanguage("en");
            setLanguage("en-US");
            window.localStorage.setItem("language", "en-US");
          }}
        >
          Eng
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            changeLanguage("es");
            setLanguage("es-ES");
            window.localStorage.setItem("language", "es-ES");
          }}
        >
          Esp
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();                                                       
            changeLanguage("ru");                                                                                 
            setLanguage("ru");
            window.localStorage.setItem("language", "ru");
          }}
        >
          Rus
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            changeLanguage("ua");
            setLanguage("ua");
            window.localStorage.setItem("language", "ua");
          }}
        >
          Ukr
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LangMenu;
