import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { data } from "./data";

const HowItWork = ({ setStepForm }) => {
  const useStyles = makeStyles({
    main: {
      padding: "0px 32px",
    },
    paragraph: {
      fontWeight: 600,
    },
  });

  useEffect(() => {
    setStepForm(false);
  }, [setStepForm]);

  const classes = useStyles();

  const { t, ready } = useTranslation();

  if (ready) {
    return (
      <Box className={classes.main}>
        {data.map((item, index) => (
          <Box key={index}>
            {item.url && (
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                paddingTop={index !== 0 ? 6 : 2}
              >
                {item.url && (
                  <ReactPlayer
                    width={450}
                    height={265}
                    controls={true}
                    url={item.url}
                    style={{
                      border: "5px solid #96b0c1",
                      borderRadius: "10px",
                      backgroundColor: "#000",
                    }}
                  />
                )}
              </Box>
            )}

            {item.text && (
              <Typography marginTop={"25px"}>{t(item.text)}</Typography>
            )}

            {item.list && (
              <ul>
                {item?.list?.map((li, index) => (
                  <li key={index}>{t(li)}</li>
                ))}
              </ul>
            )}

            {item.note && (
              <Typography marginTop={"20px"}>{t(item.note)}</Typography>
            )}
          </Box>
        ))}
      </Box>
    );
  }

  return <span></span>;
};

export default HowItWork;
